import { storyblokEditable } from "@storyblok/react/rsc";

import { cn } from "~utils";

import type { PageContext } from "~lib/storyblok";
import { RichText } from "../rich-text";
import type { MigrationMissionSectionBlok } from "./types";

type MigrationMissionSectionProps = {
  blok: MigrationMissionSectionBlok;
} & PageContext;

export function MigrationMissionSection({
  blok,
  ...context
}: MigrationMissionSectionProps) {
  return (
    <section
      id={blok.sectionId}
      data-section-type="migration-mission"
      className={cn(
        blok.theme,
        "bg-others-white px-md py-3xl text-content-text",
      )}
      {...storyblokEditable(blok)}
    >
      <RichText
        data={blok.text}
        className="t-prosi-2xl mx-auto max-w-[22em] text-center text-content-heading"
        {...context}
      />
    </section>
  );
}
