import * as Accordion from "@radix-ui/react-accordion";
import { storyblokEditable } from "@storyblok/react/rsc";

import { Icon } from "~components/ui";
import { SeedWave } from "~components/ui/seed-wave";
import type { PageContext } from "~lib/storyblok";
import { cn } from "~utils";

import { RichText } from "../rich-text";
import type { FAQSectionBlok, FAQSectionItemBlok } from "./types";

type FAQSectionProps = {
  blok: FAQSectionBlok;
} & PageContext;

export function FAQSection({ blok, ...context }: FAQSectionProps) {
  return (
    <section
      id={blok.sectionId}
      className={cn(
        blok.theme,
        "[--second-part-max-w:50rem]",
        "section relative min-h-screen w-full place-content-center overflow-hidden bg-surface/200 text-content-text",
        blok.backgroundVariant === "darker" && "bg-surface/300",
        blok.backgroundVariant === "accent" && "bg-surface/accent",
        blok.backgroundVariant === "lighter" && "bg-surface/100",
      )}
      {...storyblokEditable(blok)}
    >
      <SeedWave
        className="section-item-full absolute top-[0] z-[2] h-full w-full bg-decor/100"
        seedPatternColor={blok.seedPatternColor}
      />

      <div
        className={cn(
          "gap-md bg-surface/contrast z-10 m-auto flex w-full max-w-[58em] flex-col rounded-sm",
          "px-lg-xl py-xl-2xl",
        )}
      >
        <div className="flex flex-col gap-sm">
          <h1 className="t-strong-3xl max-w-title/sm text-pretty text-content-heading">
            {blok.title}
          </h1>

          {blok.text && (
            <RichText
              data={blok.text}
              classNames={{
                paragraph: cn(
                  "t-default-md max-w-text text-pretty text-content-text",
                ),
              }}
              {...context}
            />
          )}
        </div>

        <Accordion.Root type="multiple">
          {blok.items.map(item => (
            <FAQSectionItem key={item._uid} blok={item} {...context} />
          ))}
        </Accordion.Root>
      </div>
    </section>
  );
}

type FAQSectionItemProps = {
  blok: FAQSectionItemBlok;
} & PageContext;

function FAQSectionItem({ blok, ...context }: FAQSectionItemProps) {
  return (
    <Accordion.Item
      value={blok._uid}
      {...storyblokEditable(blok)}
      className="grid grid-cols-[max-content_1fr_max-content] gap-x-sm-md"
    >
      <Accordion.Header
        className="col-[1/4] grid"
        style={{
          // Subgrid is not 100% supported, but solves an alignment issue, and is used with fallback.
          gridTemplateColumns: "subgrid",
        }}
      >
        <Accordion.Trigger
          className={cn(
            "gap-sm-md group flex w-full items-center",
            "t-strong-lg border-border/300 py-xs-sm text-content-heading border-b pl-[0] text-left leading-snug",
            "data-[state=open]:border-secondaryUI-focus data-[state=open]:border-b-2",
            "data-[state=open]:text-secondaryUI-focus",
          )}
          style={{
            gridTemplateColumns: "subgrid",
          }}
        >
          <Icon
            weight="duotone"
            name={blok.icon}
            className="circle-icon max-sm:hidden"
          />

          <span className="max-sm:col-[1/3]">{blok.title}</span>

          <Icon
            name="CaretDown"
            className="group-data-[state=open]:rotate-180"
          />
        </Accordion.Trigger>
      </Accordion.Header>

      <Accordion.Content
        className={cn(
          "t-default-md gap-sm pl-sm-md data-[state=open]:py-md-lg flex flex-col leading-normal",
          // offset by the width of a circle icon
          "ml-[calc(32px_+_2_*_theme(spacing.xs))]",
        )}
      >
        <RichText data={blok.text} {...context} />
      </Accordion.Content>
    </Accordion.Item>
  );
}
