import { storyblokEditable } from "@storyblok/react/rsc";
import { getReadingTime } from "~lib/storyblok";
import type { PageContext } from "~lib/storyblok/page";
import { cn, formatDate, formatMinutes } from "~utils";

import { RichText } from "../rich-text";
import type { PressReleasePageBlok } from "./types";

type ArticlePageProps = {
  blok: PressReleasePageBlok;
} & PageContext;

export function PressReleasePage({ blok, ...context }: ArticlePageProps) {
  const readingTime = getReadingTime(blok.text);

  if (blok.isDisabled) {
    return <code className="flex p-lg">Article disabled for this market</code>;
  }

  return (
    <main
      data-page-type="press-release"
      {...storyblokEditable(blok)}
      className="section-sky t-default-sm bg-others-white text-content-text leading-relaxed"
    >
      <div className="mx-auto mb-base grid max-w-[75ch] gap-base px-sm pt-3xl-4xl pb-2xl">
        <div className="t-default-sm flex place-items-center gap-[1ch]">
          <span className="t-strong-sm">Press release</span>
          <span className="mb-[0.5em] font-extrabold">.</span>
          <span>
            {formatDate(blok.date || context.meta.created_at, context.locale)}
          </span>
          <span className="mb-[0.5em] font-extrabold">.</span>
          <span>{formatMinutes(readingTime?.minutes, context.locale)}</span>
        </div>

        <h1 className="t-strong-3xl text-content-heading">{blok.title}</h1>

        <div>
          <RichText
            data={blok.text}
            {...context}
            classNames={{
              paragraph: cn("mb-sm text-pretty [&_a]:text-satellite-accent"),
              heading: cn(
                "t-strong-lg mt-lg mb-sm max-w-[45ch] text-balance text-content-heading leading-tight",
              ),
              horizontal_rule: cn("my-sm"),
            }}
          />
        </div>
      </div>
    </main>
  );
}
