import * as Accordion from "@radix-ui/react-accordion";
import { renderRichText, storyblokEditable } from "@storyblok/react/rsc";
import Image from "next/image";
import { Icon } from "~components/ui";

import { buildImageLoader } from "~lib/storyblok";
import { cn } from "~utils";
import type { MigrationExpandableTileBlok } from "./types";

type MigrationExpandableTileProps = {
  blok: MigrationExpandableTileBlok;
  moreLabel: string;
};

export function MigrationExpandableTile({
  blok,
  moreLabel,
}: MigrationExpandableTileProps) {
  const richSubtitle = renderRichText(blok.subtitle);

  return (
    <section
      data-section-type="migration-expandable-tile"
      className="grid w-full gap-md-lg overflow-hidden rounded-lg bg-surface/100 p-md md:grid-cols-[auto,1fr] lg:p-[0]"
      {...storyblokEditable(blok)}
    >
      <Image
        width={260}
        height={260}
        sizes="260px"
        src={blok.image.filename ?? ""}
        loader={buildImageLoader({
          aspectRatio: 1 / 1,
          focus: blok.image.focus,
        })}
        alt={blok.image.alt ?? ""}
        title={blok.image.title ?? ""}
        className="h-[8em] w-[8em] sm:h-[12.5em] sm:w-[12.5em]"
      />

      <div className="flex w-full flex-col gap-md-lg pt-sm-md pr-md-lg pb-3xs">
        <div className="flex flex-col gap-xs">
          <h1 className="t-strong-lg text-content-heading">{blok.title}</h1>

          <h2
            className="t-strong-sm text-content-base"
            // biome-ignore lint/security/noDangerouslySetInnerHtml: CMS is safe
            dangerouslySetInnerHTML={{ __html: richSubtitle }}
          />

          <p className="-mt-2xs t-prose-sm text-content-text">{blok.text}</p>
        </div>

        <div>
          <Accordion.Root type="single" collapsible>
            <Accordion.Item value={blok._uid}>
              <Accordion.Header>
                <Accordion.Trigger
                  className={cn(
                    "group flex w-full items-center justify-between",
                    "t-prose-md border-border/200 border-t-[1px] p-xs-sm pl-[0] text-content-heading",
                  )}
                >
                  <span className="t-strong-sm">{moreLabel}</span>
                  <Icon
                    name="CaretDown"
                    className="t-strong-lg duration-100 group-data-[state=open]:rotate-180"
                  />
                </Accordion.Trigger>
              </Accordion.Header>

              <Accordion.Content className="t-default-sm leading-normal data-[state=open]:pb-md">
                {blok.expandableText}
              </Accordion.Content>
            </Accordion.Item>
          </Accordion.Root>
        </div>
      </div>
    </section>
  );
}
