"use client";

import { renderRichText } from "@storyblok/react";
import Image from "next/image";
import { useState } from "react";
import { ImageGallery as ImageGalleryUI } from "~components/ui";
import {
  buildImageLoader,
  getImageDimensions,
  imageLoader,
} from "~lib/storyblok";
import { cn } from "~utils";
import type { ImageGalleryBlok } from "./types";

type ImageGalleryProps = {
  blok: ImageGalleryBlok;
  className?: string;
};

export function ImageGallery({ blok, className }: ImageGalleryProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <aside
      className={cn(
        "grid grid-cols-[repeat(auto-fill,minmax(12em,1fr))] gap-sm",
        className,
      )}
    >
      {blok.items.slice(0, 3).map(item => (
        <button key={item._uid} onClick={() => setIsOpen(true)} type="button">
          <Image
            width="300"
            height="300"
            sizes="(max-width: 1024px) 100vw, 12em"
            src={item.image.filename ?? ""}
            loader={buildImageLoader({
              aspectRatio: 1 / 1,
              focus: item.image.focus,
            })}
            alt={item.image.alt ?? ""}
            className="overflow-hidden rounded-sm"
          />
        </button>
      ))}

      <ImageGalleryUI.Root open={isOpen} onOpenChange={setIsOpen}>
        {blok.items.map(item => {
          return (
            <ImageGalleryUI.Item key={item._uid}>
              <figure className="flex flex-col place-content-start">
                <Image
                  {...getImageDimensions(item.image)}
                  sizes="100vw"
                  src={item.image.filename ?? ""}
                  loader={imageLoader}
                  alt={item.image.alt ?? ""}
                  className="overflow-hidden object-cover object-center md:rounded-t-sm"
                />
                <figcaption
                  // biome-ignore lint/security/noDangerouslySetInnerHtml: CMS is safe
                  dangerouslySetInnerHTML={{
                    __html: renderRichText(item.caption),
                  }}
                  className="block flex-shrink-0 bg-surface/contrast p-sm md:rounded-b-sm"
                />
              </figure>
            </ImageGalleryUI.Item>
          );
        })}
      </ImageGalleryUI.Root>
    </aside>
  );
}
