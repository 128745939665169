import { storyblokEditable } from "@storyblok/react/rsc";
import { Button } from "~components/ui";
import { type PageContext, getLinkProps } from "~lib/storyblok";
import { cn } from "~utils";
import StatsItem from "./stats-item";
import type { MigrationHeroSectionBlok } from "./types";

type MigrationHeroSectionProps = {
  blok: MigrationHeroSectionBlok;
} & PageContext;

export function MigrationHeroSection({
  blok,
  ...context
}: MigrationHeroSectionProps) {
  const statsCount = [
    blok.stats1Text,
    blok.stats2Text,
    blok.stats3Text,
    blok.stats4Text,
    blok.stats5Text,
  ].filter(i => !!i).length;

  return (
    <section
      id={blok.sectionId}
      data-section-type="migration-hero"
      className={cn(blok.theme, "flex w-full flex-col text-content-heading")}
      {...storyblokEditable(blok)}
    >
      <div className="flex w-full flex-col items-center gap-sm bg-surface/100 px-lg py-3xl pt-4xl">
        <h1 className="t-strong-3xl sm:t-strong-5xl flex flex-col items-center text-center">
          <span>{blok.titleLine1}</span>
          <span>{blok.titleLine2}</span>
        </h1>

        <p className="t-strong-lg max-w-[40em] text-center text-content-text">
          {blok.text}
        </p>

        <Button
          arrow
          {...getLinkProps(blok.ctaLink, context)}
          className="mt-sm self-center"
        >
          {blok.ctaText}
        </Button>
      </div>

      <div className="flex w-full flex-col gap-lg bg-surface/200 px-xl py-2xl">
        {(blok.bottomHeader || blok.bottomText) && (
          <div className="mx-auto flex max-w-[74ch] flex-col gap-sm">
            {blok.bottomHeader && (
              <h1 className="t-strong-xl sm:t-strong-2xl mx-auto text-center font-semibold sm:font-semibold">
                <span>{blok.bottomHeader}</span>
              </h1>
            )}

            {blok.bottomText && (
              <p className="t-prose-md items-center text-center">
                {blok.bottomText}
              </p>
            )}
          </div>
        )}

        <div
          className={cn(
            "flex items-center justify-center",
            statsCount === 5
              ? "mx-auto max-w-screen-xl flex-wrap gap-lg max-sm:flex-col sm:items-start"
              : "flex-col sm:flex-row sm:items-start",
            {
              "gap-3xl": statsCount <= 2,
              "gap-xl": statsCount > 2 && statsCount < 5,
            },
          )}
        >
          <StatsItem
            imageName={blok.stats1Image}
            title={blok.stats1Title}
            text={blok.stats1Text}
            statsCount={statsCount}
          />
          <StatsItem
            imageName={blok.stats2Image}
            title={blok.stats2Title}
            text={blok.stats2Text}
            statsCount={statsCount}
          />
          {blok.stats3Text && (
            <StatsItem
              imageName={blok.stats3Image}
              title={blok.stats3Title}
              text={blok.stats3Text}
              statsCount={statsCount}
            />
          )}
          {blok.stats4Text && (
            <StatsItem
              imageName={blok.stats4Image}
              title={blok.stats4Title}
              text={blok.stats4Text}
              statsCount={statsCount}
            />
          )}
          {blok.stats5Text && (
            <StatsItem
              imageName={blok.stats5Image}
              title={blok.stats5Title}
              text={blok.stats5Text}
              statsCount={statsCount}
            />
          )}
        </div>
      </div>
    </section>
  );
}
