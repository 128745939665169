"use client";

import { type ReactNode, createContext, useContext } from "react";

type EnvContextValue = {
  storyblokPreviewToken: string;
};

const EnvContext = createContext<EnvContextValue | null>(null);

export function useEnvironment() {
  const env = useContext(EnvContext);

  if (!env) {
    console.error("Environment variables are not available.");
  }

  return env;
}

type EnvProviderProps = EnvContextValue & {
  children: ReactNode;
};

export function EnvProvider(props: EnvProviderProps) {
  const { children, ...rest } = props;

  return <EnvContext.Provider value={rest}>{children}</EnvContext.Provider>;
}
