import { type ArticleType, articleTypes } from "~components/types";
import type { PageContext, SubPage } from "~lib/storyblok";
import type { ArticlePageBlok } from "../article-page";
import type { ConfigBlok } from "../config";
import type { NewsPageBlok, NewsPageCategory, NewsPageItemType } from "./types";

export function buildCategoryLink(
  pathName: string,
  category: NewsPageCategory,
  context: PageContext,
) {
  const search = new URLSearchParams(context.searchParams);

  search.set("category", category);

  return `${pathName}?${search}`;
}

export function sortPages(subPages: SubPage<NewsPageItemType>[]) {
  if (!subPages) return [];

  return subPages.sort((a, b) =>
    new Date(a.story.content.date || a.story.created_at) >
    new Date(b.story.content.date || b.story.created_at)
      ? -1
      : 1,
  );
}

type CategoryFilters = {
  contentTypeFilter?: NewsPageItemType["component"];
  fieldTypeFilter?: ArticleType;
};

export function getCategoryFilters(
  category: NewsPageCategory | undefined,
): CategoryFilters {
  if (!category) return {};

  if (articleTypes.includes(category)) {
    return {
      contentTypeFilter: "ArticlePage",
      fieldTypeFilter: category as ArticleType,
    };
  }

  if (category === "event") {
    return { contentTypeFilter: "EventPage" };
  }

  if (category === "press-release") {
    return { contentTypeFilter: "PressReleasePage" };
  }

  return {};
}

export function getCategory(item: NewsPageItemType) {
  if (item.component === "ArticlePage") {
    return item.type;
  }

  const lookup: Record<
    Exclude<NewsPageItemType, ArticlePageBlok>["component"],
    NewsPageCategory
  > = {
    EventPage: "event",
    PressReleasePage: "press-release",
  };

  return lookup[item.component] ?? "all";
}

export function getCategoryFilterTranslation(
  type: NewsPageCategory,
  blok: NewsPageBlok,
) {
  const lookup: Record<NewsPageCategory, string> = {
    "press-release": blok.filterPress,
    all: blok.filterAll,
    event: blok.filterEvents,
    blog: blok.filterBlog,
    news: blok.filterNews,
    insight: blok.filterInsights,
    impact: blok.filterImpact,
  };

  return lookup[type] ?? type;
}

type NewsItemCategory = Exclude<NewsPageCategory, "all">;

export function getCategoryLabelTranslation(
  type: NewsPageCategory,
  blok: ConfigBlok,
) {
  const lookup: Record<NewsItemCategory, string> = {
    "press-release": blok.categoryPress,
    event: blok.categoryEvent,
    blog: blok.categoryBlog,
    news: blok.categoryNews,
    insight: blok.categoryInsight,
    impact: blok.categoryImpact,
  };
  return lookup[type as NewsItemCategory] ?? type;
}
