import type { ISbRichtext } from "@storyblok/react/rsc";
import {
  MARK_LINK,
  NODE_HEADING,
  NODE_HR,
  NODE_LI,
  NODE_PARAGRAPH,
} from "storyblok-rich-text-react-renderer";
import {
  type PageContext,
  getLinkMarkProps,
  isWrapperNode,
} from "~lib/storyblok";
import { RichText } from "../rich-text";
import type { InfoPageBlok } from "./types";

type InfoPageProps = {
  blok: InfoPageBlok;
} & PageContext;

export function InfoPage({ blok, ...context }: InfoPageProps) {
  return (
    <main
      data-page-type="info"
      className="section section-sky t-default-sm place-items-center leading-relaxed"
    >
      <div className="grid max-w-[75ch] gap-base">
        <h1 className="t-strong-3xl max-w-title/sm text-balance text-content-heading">
          {blok.title}
        </h1>

        <InfoPageBody text={blok.text} {...context} />
      </div>
    </main>
  );
}

type InfoPageBodyProps = {
  text: ISbRichtext;
} & PageContext;

function InfoPageBody({ text, ...context }: InfoPageBodyProps) {
  return (
    <div>
      <RichText
        data={text}
        {...context}
        markResolvers={{
          [MARK_LINK]: (children, props) => (
            <a {...getLinkMarkProps(props, context)}>{children}</a>
          ),
        }}
        nodeResolvers={{
          [NODE_PARAGRAPH]: children => {
            // Unwrap elements when there is no need for extra paragraph
            if (isWrapperNode(children)) return <>{children}</>;

            return (
              <p className="mb-sm text-pretty [&_a]:text-satellite-accent [&_a]:underline">
                {children}
              </p>
            );
          },
          [NODE_HEADING]: (children, { level }) => {
            switch (level) {
              case 2:
                return (
                  <h2 className="t-strong-xl mt-lg mb-sm max-w-[45ch] text-balance text-content-heading leading-tight">
                    {children}
                  </h2>
                );
              default:
                return (
                  <h3 className="t-strong-lg mt-lg mb-sm max-w-[45ch] text-balance text-content-heading leading-tight">
                    {children}
                  </h3>
                );
            }
          },
          [NODE_LI]: children => (
            <li className="list-item [&_p]:mb-[0px]">{children}</li>
          ),
          [NODE_HR]: () => <hr className="my-sm" />,
        }}
      />
    </div>
  );
}
