import { storyblokEditable } from "@storyblok/react/rsc";
import Image from "next/image";
import { Icon } from "~components/ui";
import {
  type PageContext,
  buildImageLoader,
  getLinkProps,
} from "~lib/storyblok";
import { cn } from "~utils";
import { getSurfaceVariant } from "~utils/style";
import { RichText, isTextEmpty } from "../rich-text/rich-text";
import type { PersonSectionBlok } from "./types";

type PersonSectionProps = {
  blok: PersonSectionBlok;
  className?: string;
} & PageContext;

export function PersonSection({
  blok,
  className,
  ...context
}: PersonSectionProps) {
  const person = blok.person?.content ?? {};

  const title = blok.title || person.name;
  const subtitle = blok.subtitle || person.role;
  const text = blok.showBio
    ? isTextEmpty(blok.text)
      ? person.bio
      : blok.text
    : null;

  return (
    <section
      className={cn(
        blok.theme,
        blok.backgroundVariant && getSurfaceVariant(blok.backgroundVariant),
        "[--person-section-max-w:86rem] [--person-section-px:theme(spacing.sm)]",
        "flex place-content-center",
        "px-[var(--person-section-px)] text-content-text",
        blok.topPad === "overlap" && "-mt-md-lg",
        blok.topPad === "minimal" && "pt-md-lg",
        (!blok.topPad || blok.topPad === "default") && "pt-lg-xl",
        blok.bottomPad === "overlap" && "-mb-md-lg",
        blok.bottomPad === "minimal" && "pb-md-lg",
        (!blok.bottomPad || blok.bottomPad === "default") && "pb-lg-xl",
        className,
      )}
      {...storyblokEditable(blok)}
    >
      <div
        className={cn(
          "flex w-full max-w-[var(--person-section-max-w)] flex-col place-items-start gap-md-lg",
          "rounded-md border border-border/300 p-md-lg text-content-text",
          !text && "p-sm-md pr-md-lg",
        )}
      >
        <div className="flex w-full content-center gap-xs-sm max-sm:flex-wrap">
          {person.image?.filename && (
            <Image
              width={90}
              height={90}
              sizes="90px"
              src={person.image.filename ?? ""}
              loader={buildImageLoader({
                aspectRatio: 1 / 1,
                focus: person.image.focus,
              })}
              alt={person.image?.alt ?? ""}
              title={person.image?.title ?? ""}
              className={cn(
                "aspect-square shrink-0 overflow-hidden rounded-full",
              )}
            />
          )}
          <div className="flex w-full flex-wrap items-center justify-between">
            <div className="flex flex-col">
              <h2 className="t-prosi-2xl text-content-heading leading-none">
                {title}
              </h2>

              {subtitle && (
                <span className="t-default-sm leading-relaxed">{subtitle}</span>
              )}
            </div>

            {blok.contactOptions?.length && (
              <ul className="t-strong-lg flex">
                {blok.contactOptions.includes("linked-in") &&
                  person.linkedIn && (
                    <li>
                      <a
                        {...getLinkProps(person.linkedIn, context)}
                        className="flex p-3xs"
                      >
                        <Icon name="LinkedinLogo" />
                      </a>
                    </li>
                  )}
                {blok.contactOptions.includes("instagram") &&
                  person.instagram && (
                    <li>
                      <a
                        {...getLinkProps(person.instagram, context)}
                        className="flex p-3xs"
                      >
                        <Icon name="InstagramLogo" />
                      </a>
                    </li>
                  )}
                {blok.contactOptions.includes("website") && person.link && (
                  <li>
                    <a
                      {...getLinkProps(person.link, context)}
                      className="flex p-3xs"
                    >
                      <Icon name="Link" />
                    </a>
                  </li>
                )}
                {blok.contactOptions.includes("email") && person.email && (
                  <li>
                    <a href={`mailto:${person.email}`} className="flex p-3xs">
                      <Icon name="Envelope" />
                    </a>
                  </li>
                )}
                {blok.contactOptions.includes("phone") && person.phone && (
                  <li>
                    <a href={`tel:${person.phone}`} className="flex p-3xs">
                      <Icon name="Phone" />
                    </a>
                  </li>
                )}
              </ul>
            )}
          </div>
        </div>

        {text && (
          <RichText
            data={text}
            className="t-default-md inline leading-normal"
            {...context}
          />
        )}
      </div>
    </section>
  );
}
