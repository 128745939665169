"use client";

import { type InputHTMLAttributes, useState } from "react";
import { cn } from "~utils";

import styles from "./text-field.module.css";

type TextFieldProps = {
  label: string;
} & InputHTMLAttributes<HTMLInputElement>;

export function TextField({ label, type, className, ...rest }: TextFieldProps) {
  const [isEmpty, setIsEmpty] = useState(!rest.value);

  return (
    <div
      className={cn(styles.root, className)}
      data-state={isEmpty ? "empty" : undefined}
    >
      <label htmlFor={rest.id ?? undefined}>{label}</label>
      <input
        type={type || "text"}
        onInput={ev => setIsEmpty(!ev.currentTarget.value)}
        {...rest}
      />
      <span className={styles.error} />
    </div>
  );
}
