import { type ISbRichtext, storyblokEditable } from "@storyblok/react/rsc";
import { useMemo } from "react";
import type { SectionThemes } from "~components/types";
import { VideoPlayer } from "~components/ui";
import { SeedWave } from "~components/ui/seed-wave";
import {
  type PageContext,
  type StoryblokAsset,
  getPosterUrl,
} from "~lib/storyblok";
import { cn, useScreenSize } from "~utils";
import { getSurfaceVariant } from "~utils/style";
import { RichText, isTextEmpty } from "../rich-text";

export type VideoBlok = {
  leading?: string;
  title?: string;
  text?: ISbRichtext;

  youtubeId?: string;
  youtubeTitle?: string;
  video?: StoryblokAsset;
  poster?: StoryblokAsset;
  captions?: string;
  autoplay?: boolean;

  // Styles
  theme?: SectionThemes;
  backgroundVariant?: string;
  pattern?: boolean;
  patternColor?: string;
};

type VideoProps = {
  blok: VideoBlok;
  className?: string;
} & PageContext;

export function Video({ blok, className, ...context }: VideoProps) {
  const { isMobile } = useScreenSize();

  const hasTextContent = useMemo(
    () => blok.title || !isTextEmpty(blok.text),
    [blok.title, blok.text],
  );

  return (
    <section
      className={cn(
        "[--video-max-w:100%] [--video-no-text-pt:theme(xl-2xl)] [--video-px:0px] [--video-py:theme(spacing.lg-xl)]",
        "relative flex flex-col place-items-center gap-sm px-[var(--video-px)] py-[var(--video-py)]",
        !hasTextContent && "pt-var(--video-no-text-pt)",
        blok.theme,
        getSurfaceVariant(blok.backgroundVariant),
        className,
      )}
      {...storyblokEditable(blok)}
    >
      {hasTextContent && (
        <div className="z-10 flex w-full max-w-[var(--video-max-w)] flex-col gap-sm">
          {blok.leading && (
            <h2 className="-mb-xs t-prosi-md max-w-sub text-pretty text-content-heading leading-tight">
              {blok.leading}
            </h2>
          )}
          {blok.title && (
            <h1 className="t-strong-xl max-w-title text-balance text-content-heading">
              {blok.title}
            </h1>
          )}

          {blok.text && (
            <RichText
              className="max-w-text text-pretty leading-normal"
              data={blok.text}
              {...context}
            />
          )}
        </div>
      )}

      {!blok.youtubeId && blok.video?.filename && (
        <VideoPlayer
          src={blok.video.filename}
          captions={blok.captions}
          poster={
            blok.poster
              ? getPosterUrl({
                  image: blok.poster,
                  width: isMobile ? 600 : 1280,
                })
              : undefined
          }
          {...context}
          className="z-10 max-w-[var(--video-max-w)]"
        />
      )}

      {blok.youtubeId && (
        <iframe
          src={`https://www.youtube.com/embed/${blok.youtubeId}`}
          title={blok.youtubeTitle}
          width={isMobile ? 600 : 1280}
          className={cn(
            "z-10 w-full max-w-[var(--video-max-w)] overflow-hidden rounded-md",
            isMobile ? "aspect-[4/3]" : "aspect-video",
          )}
        />
      )}

      {blok.pattern ? (
        <SeedWave
          className="absolute bottom-[0] z-0 aspect-[4/3] w-full bg-decor/accent"
          seedPatternColor={blok.patternColor as string | undefined}
        />
      ) : null}
    </section>
  );
}
