import { renderRichText, storyblokEditable } from "@storyblok/react/rsc";

import { cn } from "~utils";

import { Button } from "~components/ui";
import { type PageContext, getLinkProps } from "~lib/storyblok";
import type { MigrationTextSectionBlok } from "./types";

type MigrationTextSectionProps = {
  blok: MigrationTextSectionBlok;
} & PageContext;

export function MigrationTextSection({
  blok,
  ...context
}: MigrationTextSectionProps) {
  return (
    <section
      id={blok.sectionId}
      data-section-type="migration-text"
      className={cn(blok.theme, "bg-surface/100 text-content-text")}
      {...storyblokEditable(blok)}
    >
      <div className="mx-auto grid max-w-screen-xl place-items-start gap-lg-xl px-sm py-2xl md:grid-cols-2 md:px-lg-xl">
        <div className="grid place-items-start gap-sm">
          <h1 className="t-strong-2xl max-w-title text-balance text-content-heading">
            {blok.title}
          </h1>

          <div
            className="t-prose-md max-w-text text-pretty"
            // biome-ignore lint/security/noDangerouslySetInnerHtml: CMS is safe
            dangerouslySetInnerHTML={{ __html: renderRichText(blok.text) }}
          />

          {blok.ctaLink && blok.ctaText && (
            <Button {...getLinkProps(blok.ctaLink, context)} className="mt-xs">
              {blok.ctaText}
            </Button>
          )}
        </div>

        {blok.extraText && (
          <aside
            className="t-default-sm [&_b]:t-strong-sm mt-3xs grid max-w-text place-items-start gap-sm text-pretty font-sans"
            // biome-ignore lint/security/noDangerouslySetInnerHtml: CMS is safe
            dangerouslySetInnerHTML={{ __html: renderRichText(blok.extraText) }}
          />
        )}
      </div>
    </section>
  );
}
