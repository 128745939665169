"use client";

import { useSearchParams } from "next/navigation";
import {
  type ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from "react";

const STORAGE_KEY = "utm_params";

type UtmContextValue = {
  utmParams: Record<string, string>;
};

const UtmContext = createContext<UtmContextValue | null>(null);

export function useUtm() {
  const ctx = useContext(UtmContext);

  if (!ctx) {
    throw new Error("Utm data is not available.");
  }

  return ctx;
}

type UtmProviderProps = {
  children: ReactNode;
};

export function UtmProvider(props: UtmProviderProps) {
  const { children } = props;

  const searchParams = useSearchParams();
  const storedParams = useMemo(getStoredParams, []);

  const utmParams = useMemo(() => {
    const lookup = { ...storedParams };

    for (const [key, value] of searchParams) {
      if (!key.startsWith("utm_")) continue;

      // Overwrite with current ones
      lookup[key] = value;
    }

    return lookup;
  }, [searchParams, storedParams]);

  useEffect(() => updateStoredParams(utmParams), [utmParams]);

  return (
    <UtmContext.Provider value={{ utmParams }}>{children}</UtmContext.Provider>
  );
}

function getStoredParams(): Record<string, string> {
  try {
    const rawValue = window.sessionStorage.getItem(STORAGE_KEY);
    if (!rawValue) return {};

    return JSON.parse(rawValue);
  } catch (err) {
    return {};
  }
}

function updateStoredParams(value: Record<string, string>) {
  try {
    window.sessionStorage.setItem(STORAGE_KEY, JSON.stringify(value));
  } catch (err) {}
}
