"use client";

import { type SelectHTMLAttributes, useState } from "react";
import { cn } from "~utils";

import { Icon } from "../icon";
import styles from "./select-field.module.css";

type SelectFieldProps = {
  label: string;
} & SelectHTMLAttributes<HTMLSelectElement>;

export function SelectField({
  label,
  children,
  className,
  ...rest
}: SelectFieldProps) {
  const [isEmpty, setIsEmpty] = useState(!rest.value);

  return (
    <div
      className={cn(styles.root, className)}
      data-state={isEmpty ? "empty" : undefined}
    >
      <label htmlFor={rest.id}>{label}</label>

      <select {...rest} onChange={ev => setIsEmpty(!ev.currentTarget.value)}>
        {children}
      </select>

      <Icon name="CaretDown" className={styles.caret} />
    </div>
  );
}
