"use client";

import {
  type ISbRichtext,
  type SbBlokData,
  storyblokEditable,
} from "@storyblok/react/rsc";
import Image from "next/image";
import { useState } from "react";

import { Button, Icon, Rails } from "~components/ui";
import {
  type PageContext,
  type StoryblokLink,
  buildImageLoader,
  getLinkProps,
} from "~lib/storyblok";
import { cn } from "~utils";

import type { SectionThemes } from "~components/types";
import { SeedWave } from "~components/ui/seed-wave";
import { getSurfaceVariant } from "~utils/style";
import type { TileItemBlok } from "../../tile-section";
import { RichText } from "../rich-text";

export type TilesBlok = {
  leading?: string;
  title?: string;
  text?: ISbRichtext;
  ctaLink?: StoryblokLink;
  ctaText?: string;
  items: TileItemBlok[];

  // Styles
  theme: SectionThemes;
  backgroundVariant?: string;
  pattern?: boolean;
  patternColor?: string;
};

type TileSectionProps = {
  className?: string;
  viewportClassName?: string;
  blok: TilesBlok;
} & PageContext;

export function Tiles({ blok, className, ...context }: TileSectionProps) {
  const [isPrevDisabled, setIsPrevDisabled] = useState(false);
  const [isNextDisabled, setIsNextDisabled] = useState(false);

  const isNumbered = !blok.items.some(item => item.icon);

  return (
    <Rails.Root
      className={cn(
        "[--tiles-max-w:100%] [--tiles-px:0px]",
        "[--tiles-offset-x:max(calc(50vw-(var(--tiles-max-w)/2)),var(--tiles-px))]",
        "relative grid place-items-center",
        blok.theme,
        getSurfaceVariant(blok.backgroundVariant),
        className,
      )}
      style={{
        gridTemplateColumns:
          "var(--tiles-offset-x) minmax(auto,var(--tiles-max-w)) var(--tiles-offset-x)",
      }}
      {...storyblokEditable(blok)}
    >
      <div className="z-10 col-[2/3] flex w-full flex-wrap justify-between gap-lg">
        <div className="flex grow flex-col gap-xs">
          {blok.leading && (
            <div className="t-prosi-md max-w-leading text-balance text-content-heading">
              {blok.leading}
            </div>
          )}

          {blok.title && (
            <h1 className="t-strong-2xl max-w-[21ch] text-balance text-content-heading">
              {blok.title}
            </h1>
          )}

          {blok.text && (
            <RichText
              className="t-prose-md max-w-sub text-pretty"
              data={blok.text}
              {...context}
            />
          )}

          {blok.ctaLink && blok.ctaText && (
            <Button
              arrow
              variant="secondary"
              className="mr-auto"
              {...getLinkProps(blok.ctaLink, context)}
            >
              {blok.ctaText}
            </Button>
          )}
        </div>

        <div
          className={cn(
            "flex gap-sm place-self-end",
            isPrevDisabled && isNextDisabled && "hidden",
          )}
        >
          <Rails.Control
            action="prev"
            isDisabled={isPrevDisabled}
            onDisabledChanged={setIsPrevDisabled}
          />
          <Rails.Control
            action="next"
            isDisabled={isNextDisabled}
            onDisabledChanged={setIsNextDisabled}
          />
        </div>
      </div>

      <Rails.Viewport
        className={cn(
          "z-10 col-span-3 flex w-full gap-xs-sm overflow-x-auto md:gap-md",
          "scrollbar-hidden z-10 snap-x snap-mandatory scroll-px-[var(--tiles-offset-x)] px-[var(--tiles-offset-x)]",
        )}
      >
        {blok.items.map((item, index) => (
          <TilesItem
            key={item._uid}
            blok={item}
            index={index}
            isNumbered={isNumbered}
            {...context}
          />
        ))}
      </Rails.Viewport>

      {blok.pattern ? (
        <SeedWave
          className="absolute bottom-[0] z-0 aspect-[4/3] w-full bg-decor/accent"
          seedPatternColor={blok.patternColor as string | undefined}
        />
      ) : null}
    </Rails.Root>
  );
}

type TilesItemProps = {
  blok: TileItemBlok;
  index: number;
  isNumbered?: boolean;
  isWide?: boolean;
} & PageContext;

function TilesItem({ blok, index, isNumbered, ...context }: TilesItemProps) {
  return (
    <Rails.Item
      className={cn(
        blok.theme,
        "w-[min(90vw,18em)] shrink-0 snap-center md:w-[min(23em,38vw)] md:snap-start",
        `[--circle-icon-p:theme("spacing.2xs")] [--circle-icon-w:28px]`,
        "overflow-hidden rounded-md md:min-h-[20em]",
        blok.variant === "lighter" && "bg-surface/100",
        blok.variant === "default" && "bg-surface/200",
        blok.variant === "darker" && "bg-surface/300",
        blok.variant === "accent" && "bg-surface/accent",
      )}
      {...storyblokEditable(blok as SbBlokData)}
    >
      {blok.image?.filename && (
        <>
          <Image
            width={307}
            height="180"
            alt={blok.image?.alt ?? ""}
            src={blok.image.filename}
            loader={buildImageLoader({
              aspectRatio: 307 / 180,
              focus: blok.image.focus,
            })}
            className="w-full md:hidden"
          />
          <Image
            width={460}
            height="240"
            sizes="(max-width: 1048px) 80vw, 40vw"
            alt={blok.image?.alt ?? ""}
            src={blok.image.filename}
            loader={buildImageLoader({
              aspectRatio: 460 / 240,
              focus: blok.image.focus,
            })}
            className="w-full max-md:hidden"
          />
        </>
      )}

      <div className="grid place-content-start gap-sm p-sm text-content-text md:p-md">
        {!isNumbered && (
          <Icon
            name={blok.icon ?? "Star"}
            weight="duotone"
            className="circle-icon"
          />
        )}

        {isNumbered && (
          <div className="t-strong-md box-content grid w-[1em] place-items-center place-self-start rounded-full border border-content-head p-xs leading-none">
            {index + 1}
          </div>
        )}

        {blok.leading && <span className="t-strong-sm">{blok.leading}</span>}

        <h2 className="t-prosi-xl text-balance">{blok.title}</h2>

        <RichText
          className="t-default-md text-pretty"
          data={blok.text}
          {...context}
        />

        {blok.ctaLink && blok.ctaText && (
          <a
            {...getLinkProps(blok.ctaLink, context)}
            className="t-strong-sm my-2xs leading-tight underline"
          >
            {blok.ctaText}
          </a>
        )}
      </div>
    </Rails.Item>
  );
}
