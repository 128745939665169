import { useState } from "react";
import type { QuoteItemBlok } from "./types";

export const useControlQuotes = (quotes: QuoteItemBlok[]) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (currentIndex < quotes.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const isPrevDisabled = currentIndex === 0;
  const isNextDisabled = currentIndex === quotes.length - 1;
  const isSingleQuote = quotes.length === 1;

  return {
    currentIndex,
    handlePrevClick,
    handleNextClick,
    isSingleQuote,
    isPrevDisabled,
    isNextDisabled,
  };
};
