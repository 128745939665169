"use client";

import { storyblokEditable } from "@storyblok/react/rsc";
import { useEffect, useRef, useState } from "react";
import { Icon } from "~components/ui";
import { cn } from "~utils";
import { DocPageGroup } from "./doc-page-group";
import type { DocPageBlok } from "./types";

type InfoPageProps = {
  blok: DocPageBlok;
};

export function DocPage({ blok }: InfoPageProps) {
  const [scrollY, setScrollY] = useState(0);
  const navRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScrollY(window.scrollY);
    });
  }, []);

  const jumpToGroup = (
    e: React.MouseEvent | React.KeyboardEvent,
    anchor: string | undefined,
  ) => {
    if (anchor) {
      document.getElementById(anchor)?.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <main
      {...storyblokEditable(blok)}
      data-page-type="doc"
      className="relative bg-[#f7f4ed]"
    >
      <div className="mx-auto max-w-screen-xl scroll-smooth">
        <div className="pt-3xl lg:float-left">
          <div
            ref={navRef}
            className={cn(
              "w-full bg-sand-300 px-lg py-md text-others-white lg:w-[400px]",
              {
                "lg:fixed lg:top-[20px]":
                  navRef.current &&
                  scrollY > navRef.current.getBoundingClientRect().y + 20,
              },
            )}
          >
            <h3 className="t-strong-lg mb-2xs">{blok.titleLinks}</h3>
            {blok.links.map(link => {
              return (
                <div className="my-3xs" key={link.url.anchor}>
                  {link.iconPhosphor && (
                    <Icon
                      className="mr-xs inline-block"
                      name={link.iconPhosphor}
                    />
                  )}
                  <button
                    type="button"
                    className="t-prose-sm"
                    onClick={e => jumpToGroup(e, link.url.anchor)}
                    onKeyDown={e => jumpToGroup(e, link.url.anchor)}
                  >
                    {link.label}
                  </button>
                </div>
              );
            })}
          </div>
        </div>
        <div className="ml-auto divide-y-[1px] divide-sand-300/30 px-xl py-md pt-xl lg:w-[calc(100%-400px)]">
          {blok.groups.map((group, i) => {
            return <DocPageGroup key={group.anchorId} blok={group} />;
          })}
        </div>
      </div>
    </main>
  );
}
