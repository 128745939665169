import { type PageContext, buildImageLoader } from "~lib/storyblok";
import { cn } from "~utils";

import Image from "next/image";
import type { LogoSectionBlok, LogoSectionItemBlok } from "./types";

type LogoSectionProps = {
  blok: LogoSectionBlok;
} & PageContext;

export function LogoSection({ blok }: LogoSectionProps) {
  return (
    <section
      id={blok.sectionId}
      className={cn("section", "bg-surface/100", blok.theme)}
    >
      <div className="flex flex-col place-items-start gap-sm">
        {blok.leading && (
          <h2 className="t-prosi-lg max-w-[45ch] text-pretty text-content-heading leading-tight">
            {blok.leading}
          </h2>
        )}

        <h1 className="t-strong-3xl max-w-title text-balance text-content-heading">
          {blok.title}
        </h1>

        <ul className="mt-md grid w-full grid-cols-4 items-center justify-between gap-xs sm:grid-cols-6 xl:grid-cols-8 sm:gap-x-lg">
          {blok.items?.map(item => (
            <LogoSectionItem key={item._uid} blok={item} />
          ))}
        </ul>
      </div>
    </section>
  );
}

type LogoSectionItemProps = {
  blok: LogoSectionItemBlok;
};

export function LogoSectionItem({ blok }: LogoSectionItemProps) {
  if (!blok.logo.filename) return <></>;

  return (
    <li className="list-none">
      <Image
        width={100}
        height={100}
        sizes="(max-width: 768px) 15vw, (max-width: 1280px) 25vw, 10vw"
        loader={buildImageLoader({
          aspectRatio: 1,
        })}
        src={blok.logo.filename}
        alt={blok.logo.alt}
      />
    </li>
  );
}
