import { StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc";
import type { PageContext } from "~lib/storyblok";
import type { DynamicPageBlok } from "./types";

type DynamicPageProps = {
  blok: DynamicPageBlok;
} & PageContext;

/**
 * The main dynamic page when building out landing pages with sections etc.
 */
export function DynamicPage({ blok, ...rest }: DynamicPageProps) {
  return (
    <main {...storyblokEditable(blok)} data-page-type="dynamic">
      {blok.body?.map(nestedBlok => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} {...rest} />
      ))}
    </main>
  );
}
