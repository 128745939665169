import { type ISbStoryData, storyblokEditable } from "@storyblok/react/rsc";
import Image from "next/image";
import {
  type PageContext,
  buildImageLoader,
  getImageDimensions,
} from "~lib/storyblok";
import { formatDateShort } from "~utils";
import { isArticlePage } from "../article-page";

type NewsPageItemProps = {
  url: string;
  story: ISbStoryData;
  categoryName: string;
} & PageContext;

export default function NewsPageItem({
  story: item,
  url,
  categoryName,
  ...context
}: NewsPageItemProps) {
  const articleItem = isArticlePage(item) ? item : null;

  if (articleItem?.content.externalLink?.url) {
    url = articleItem?.content.externalLink.url;
  }

  return (
    <a href={url} className="grid gap-sm" {...storyblokEditable(item.content)}>
      {item.content.image && (
        <Image
          {...getImageDimensions(item.content.image)}
          sizes="(max-width: 767px) 90vw, (max-width: 1000px) 50vw, 33vw"
          src={item.content.image.filename ?? ""}
          loader={buildImageLoader({
            aspectRatio: 16 / 10,
            focus: item.content.image.focus,
          })}
          alt={item.content.image.alt ?? ""}
          title={item.content.image.title ?? undefined}
          className="overflow-hidden rounded-sm"
        />
      )}

      <div className="grid gap-2xs leading-tight">
        <div className="t-default-sm flex gap-2xs">
          <span>{categoryName}</span>
          <span className="t-default-xl flex">·</span>
          <span>
            {formatDateShort(
              item.content.date || item.created_at,
              context.locale,
            )}
          </span>
        </div>

        <h2 className="t-default-lg text-satellite-accent">
          {item.content.title}
        </h2>
      </div>
    </a>
  );
}
