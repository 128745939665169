import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { cn } from "~utils";
import type { TableBlok } from "./types";

type TableProps = {
  blok: TableBlok;
  className?: string;
};

export function Table({ blok, className }: TableProps) {
  return (
    <Markdown
      remarkPlugins={[remarkGfm]}
      className={cn(
        "[&_table]:border-collapse [&_table]:border-2 [&_table]:border-content-text [&_table]:text-left",
        "[&_tr]:border-2 [&_tr]:border-content-text",
        "[&_th]:py-3xs [&_th]:pt-2xs [&_th:first-child]:pr-sm [&_th:first-child]:pl-sm [&_th]:pl-xs",
        "[&_td]:py-3xs [&_td:last-child]:pr-sm [&_td:first-child]:pl-sm [&_td]:pl-xs",
        className,
      )}
    >
      {blok.markdown}
    </Markdown>
  );
}
