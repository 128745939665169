import { storyblokEditable } from "@storyblok/react/rsc";
import Image from "next/image";
import { Button } from "~components/ui";
import {
  type PageContext,
  type StoryblokAsset,
  buildImageLoader,
  getLinkProps,
} from "~lib/storyblok";
import { cn } from "~utils";
import { RichText } from "../rich-text";
import type { SeedSectionBlok } from "./types";

type SeedSectionProps = {
  blok: SeedSectionBlok;
} & PageContext;

export function SeedSection({ blok, ...context }: SeedSectionProps) {
  return (
    <section
      id={blok.sectionId}
      data-section-type="seed"
      className={cn(
        "section overflow-hidden",
        blok.theme,
        blok.backgroundVariant,
      )}
      {...storyblokEditable(blok)}
    >
      <div
        className={cn(
          "grid grid-cols-1 gap-md [grid-template-areas:'content''seed']",
          blok.variant === "left" &&
            // For desktop left variant, the image is positioned to the bottom right side.
            // We restrain the seed max _height_ to 25em, and the content max _width_ to 38em.
            "lg:grid-cols-[38em_1fr] lg:grid-rows-[max-content_25em] lg:[grid-template-areas:'content_.''seed_seed']",
          blok.variant === "right" &&
            // For desktop right variant, the image is positioned to the left side.
            // We restrain the seed max _height_ to 38em, and the content max width to 25em.
            "lg:grid-cols-[1fr_25em] lg:grid-rows-[38em] lg:place-items-center lg:[grid-template-areas:'seed_content']",
        )}
      >
        <div
          className={cn(
            "z-10 grid place-items-start gap-sm [grid-area:content] max-lg:place-self-end",
            blok.variant === "right" && "max-w-[40ch]",
          )}
        >
          {blok.variant === "left" && blok.leading && (
            <h5 className="-mb-2xs t-prosi-xl max-w-leading font-light text-content-heading">
              {blok.leading}
            </h5>
          )}

          <h1
            className={cn(
              "max-w-title text-balance text-content-heading",
              blok.variant === "right" &&
                "t-strong-2xl lg:t-strong-3xl md:t-strong-2xl leading-tight",
              blok.variant === "left" && "t-strong-4xl-5xl leading-none",
            )}
          >
            {blok.title}
          </h1>

          {blok.variant === "left" && blok.text && (
            <RichText
              data={blok.text}
              className="t-strong-lg-xl max-w-sub whitespace-pre-line text-pretty text-content-text"
              {...context}
            />
          )}

          {blok.ctaLink && blok.ctaLabel && (
            <Button {...getLinkProps(blok.ctaLink, context)} editorial>
              {blok.ctaLabel}
            </Button>
          )}
        </div>
        {blok.variant === "left" ? (
          <SeedSectionRightImage backgroundImage={blok.backgroundImage} />
        ) : (
          <SeedSectionLeftImage backgroundImage={blok.backgroundImage} />
        )}
      </div>
    </section>
  );
}

type SeedMaskType = { backgroundImage: StoryblokAsset };

function SeedSectionLeftImage({ backgroundImage }: SeedMaskType) {
  if (!backgroundImage?.filename) return null;

  return (
    <>
      <Image
        src={backgroundImage.filename}
        width={375}
        height={475}
        alt={backgroundImage.alt || ""}
        loader={buildImageLoader({
          aspectRatio: 375 / 475,
          focus: backgroundImage.focus,
        })}
        sizes="100vw"
        className={cn(
          "z-0 [grid-area:seed] md:hidden",
          "[mask-image:url(/masks/seed-4.svg)]",
          "[mask-position:140%,-17vw] [mask-size:120%,min(125vw,28em)_auto]",
          "-mx-[var(--section-px)] w-[23em] max-w-[100vw]",
        )}
      />

      <Image
        src={backgroundImage.filename}
        width={768}
        height={940}
        alt={backgroundImage.alt || ""}
        loader={buildImageLoader({
          aspectRatio: 768 / 940,
          focus: backgroundImage.focus,
        })}
        sizes="768px"
        className={cn(
          "z-0 [grid-area:seed] max-md:hidden",
          "[mask-image:url(/masks/seed-4.svg)]",
          "[mask-position:center,right_50%_top] [mask-size:120%,auto_100%]",
          "-ml-[20vw] lg:-ml-[13vw] -mr-lg -my-[30%] max-w-[60vw] lg:max-w-[50vw]",
        )}
      />
    </>
  );
}

function SeedSectionRightImage({ backgroundImage }: SeedMaskType) {
  if (!backgroundImage?.filename) return null;

  return (
    <>
      <Image
        src={backgroundImage.filename}
        width={800}
        height={700}
        alt={backgroundImage.alt || ""}
        loader={buildImageLoader({
          aspectRatio: 8 / 7,
          focus: backgroundImage.focus,
        })}
        sizes="(max-width: 700px) 90vw, 44em"
        className={cn(
          "z-0 place-self-end [grid-area:seed] lg:hidden",
          "[mask-image:url(/masks/seed-3.svg)]",
          "-mt-[var(--section-px)] [mask-size:107%,auto_125%]",
          "-mr-[15vw] w-screen max-w-[100vw]",
        )}
      />

      <Image
        src={backgroundImage.filename}
        width={1000}
        height={800}
        alt={backgroundImage.alt || ""}
        loader={buildImageLoader({
          aspectRatio: 5 / 4,
          focus: backgroundImage.focus,
        })}
        sizes="(max-width: 700px) 90vw, 44em"
        className={cn(
          "z-0 place-self-end [grid-area:seed] max-lg:hidden",
          "[mask-image:url(/masks/seed-3.svg)]",
          "[mask-position:top] [mask-size:100%,100%_auto]",
          "-mr-[4em] -mt-[9em] -mb-[5em] w-[min(100%,50em)]",
        )}
      />
    </>
  );
}
