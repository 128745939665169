import Image from "next/image";
import { useMemo } from "react";
import {
  type PageContext,
  buildImageLoader,
  getLinkProps,
} from "~lib/storyblok";
import { cn, getFlagIcon } from "~utils";
import type { PersonBlok } from "../person/types";
import type { PersonGalleryBlok } from "./types";

type PersonGalleryProps = {
  blok: PersonGalleryBlok;
  className?: string;
} & PageContext;

export function PersonGallery({
  blok,
  className,
  ...context
}: PersonGalleryProps) {
  const items = useMemo(
    () =>
      (blok.items.length
        ? blok.items
        : blok.references.map(item => item.content)
      ).filter(item => item.link) as (PersonBlok & {
        link: NonNullable<PersonBlok["link"]>;
      })[],
    [blok.items, blok.references],
  );

  return (
    <section
      className={cn(
        "grid grid-cols-[repeat(auto-fill,minmax(14em,1fr))] gap-sm",
        className,
      )}
    >
      {items.map(item => (
        <a
          key={item._uid}
          className="grid gap-xs"
          {...getLinkProps(item.link, context)}
        >
          <Image
            width="600"
            height="600"
            sizes="(max-width: 1024px) 100vw, 360px"
            src={item.image.filename ?? ""}
            loader={buildImageLoader({
              aspectRatio: 1 / 1,
              focus: item.image.focus,
            })}
            alt={item.image.alt ?? ""}
            title={item.image.title ?? ""}
            className="overflow-hidden rounded-sm"
          />

          <div className="grid gap-3xs">
            <div className="flex place-items-center gap-2xs">
              <h3 className="t-strong-sm leading-tight">{item.name}</h3>

              {item.nationality && getFlagIcon(item.nationality) && (
                <Image
                  width="40"
                  height="40"
                  src={getFlagIcon(item.nationality)}
                  alt={item.nationality}
                  className="h-[1em] w-[1em] rounded-full"
                />
              )}
            </div>
            <p className="t-strong-base leading-tight">{item.role}</p>
          </div>
        </a>
      ))}
    </section>
  );
}
