import { renderRichText, storyblokEditable } from "@storyblok/react/rsc";
import Image from "next/image";

import { Button } from "~components/ui";
import {
  type PageContext,
  buildImageLoader,
  getLinkProps,
  unwrapParagraph,
} from "~lib/storyblok";
import { cn, useScreenSize } from "~utils";

import { useMemo } from "react";
import { SeedWave } from "~components/ui/seed-wave";
import type { ImageSectionBlok } from "./types";

type ImageSectionProps = {
  blok: ImageSectionBlok;
} & PageContext;

export function ImageSection({ blok, ...context }: ImageSectionProps) {
  const text = unwrapParagraph(renderRichText(blok.text));

  const hasText = text && blok.title;

  const { isMobile } = useScreenSize();

  const title = useMemo(
    () => (isMobile ? blok.titleMobile || blok.title : blok.title),
    [blok, isMobile],
  );

  return (
    <section
      id={blok.sectionId}
      data-section-type="image"
      className={cn(
        blok.theme,
        "section gap-y-3xl-4xl pt-[0]",
        !hasText && "py-[0]",
        blok.theme === "section-satellite" && "bg-surface/200",
      )}
      {...storyblokEditable(blok)}
    >
      <div className="section-item-full relative overflow-hidden text-surface/100">
        {blok.image.filename && (
          <>
            <Image
              src={blok.image.filename}
              width={400}
              height={700}
              sizes="100vw"
              loader={buildImageLoader({
                aspectRatio: 4 / 7,
                focus: blok.image.focus,
              })}
              alt={blok.image.alt}
              className="min-h-screen w-full object-cover object-center md:hidden"
            />

            <Image
              src={blok.image.filename}
              width={1600}
              height={900}
              sizes="100vw"
              loader={buildImageLoader({
                aspectRatio: 16 / 9,
                focus: blok.image.focus,
              })}
              alt={blok.image.alt}
              className="min-h-screen w-full object-cover object-center max-md:hidden"
            />
          </>
        )}

        <SeedWave
          className="section-item-full absolute top-[0] h-full w-full bg-decor/100"
          seedPatternColor={blok.seedPatternColor}
        />
      </div>

      {blok.title && (
        <div className="grid place-items-end">
          <div className="grid max-w-[40em] place-items-start gap-md text-content-text">
            <h1 className="t-strong-4xl-5xl max-w-title/xs text-balance text-content-heading">
              {title}
            </h1>

            <p
              // biome-ignore lint/security/noDangerouslySetInnerHtml: CMS is safe
              dangerouslySetInnerHTML={{ __html: text }}
              className="t-strong-lg-xl"
            />

            {blok.ctaLink && (
              <Button
                {...getLinkProps(blok.ctaLink, context)}
                variant="secondary"
                arrow
              >
                {blok.ctaText}
              </Button>
            )}
          </div>
        </div>
      )}
    </section>
  );
}
