"use client";

import { type SbBlokData, storyblokEditable } from "@storyblok/react/rsc";
import { useState } from "react";

import { Button, Icon, Rails } from "~components/ui";
import { type PageContext, getLinkProps } from "~lib/storyblok";
import { cn } from "~utils";

import { RichText } from "../rich-text";
import type {
  CarbonCreditsSectionBlok,
  CarbonCreditsSectionItemBlok,
  CarbonCreditsSectionListItemBlok,
} from "./types";

type CarbonCreditsSectionProps = {
  blok: CarbonCreditsSectionBlok;
} & PageContext;

export function CarbonCreditsSection({
  blok,
  ...context
}: CarbonCreditsSectionProps) {
  const [isPrevDisabled, setIsPrevDisabled] = useState(false);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  return (
    <Rails.Root
      id={blok.sectionId}
      data-section-type="carbon-credits"
      className={cn(blok.theme, "section")}
      {...storyblokEditable(blok)}
    >
      <div className="flex flex-col place-items-start gap-sm">
        {blok.leading && (
          <h2 className="-mb-2xs t-prosi-lg max-w-[45ch] text-pretty text-content-heading leading-tight">
            {blok.leading}
          </h2>
        )}

        <h1 className="t-strong-3xl max-w-title text-balance text-content-heading">
          {blok.title}
        </h1>

        <RichText
          className="t-strong-lg max-w-text/sm text-pretty text-content-text"
          data={blok.text}
          {...context}
        />

        {blok.ctaText && (
          <Button {...getLinkProps(blok.ctaLink, context)} editorial>
            {blok.ctaText}
          </Button>
        )}
      </div>

      <div className="flex flex-col justify-between gap-lg md:flex-row md:items-end">
        {blok.sectionText ? (
          <div className="flex flex-col gap-2xs">
            <h3 className="t-strong-xl text-content-heading">
              {blok.sectionTitle}
            </h3>

            <RichText
              className="t-default-md max-w-text text-pretty"
              data={blok.sectionText}
              {...context}
            />
          </div>
        ) : (
          <div />
        )}

        <div
          className={cn(
            "flex gap-sm align-bottom",
            isPrevDisabled && isNextDisabled && "hidden",
          )}
        >
          <Rails.Control
            action="prev"
            isDisabled={isPrevDisabled}
            onDisabledChanged={setIsPrevDisabled}
          />
          <Rails.Control
            action="next"
            isDisabled={isNextDisabled}
            onDisabledChanged={setIsNextDisabled}
          />
        </div>
      </div>

      <Rails.Viewport asChild>
        <div
          className={cn(
            "section-item-full flex gap-sm overflow-x-auto",
            "scrollbar-hidden snap-x snap-mandatory scroll-px-[var(--section-offset-x)] px-[var(--section-offset-x)]",
          )}
        >
          {blok.items?.map(nestedBlok => (
            <CarbonCreditsSectionItem blok={nestedBlok} key={nestedBlok._uid} />
          ))}
        </div>
      </Rails.Viewport>

      {blok.enableBottomSection && (
        <div className="flex max-w-[64ch] flex-col justify-center gap-2xl justify-self-end">
          <div className="flex flex-col gap-lg">
            <div className="flex flex-col gap-xs-sm">
              <h2 className="t-prosi-xl text-balance">{blok.bottomLeading}</h2>

              <h1 className="t-strong-3xl text-balance">{blok.bottomTitle}</h1>
            </div>

            <ul>
              {blok.bottomItems?.map(item => (
                <HowToSellItem key={item._uid} blok={item} {...context} />
              ))}
            </ul>
          </div>

          {blok.bottomText && (
            <RichText
              className="t-strong-lg flex flex-col gap-sm text-pretty"
              data={blok.bottomText}
              {...context}
            />
          )}
        </div>
      )}
    </Rails.Root>
  );
}

type CarbonCreditsSectionItemProps = {
  blok: CarbonCreditsSectionItemBlok;
};

export function CarbonCreditsSectionItem({
  blok,
}: CarbonCreditsSectionItemProps) {
  return (
    <Rails.Item
      className="grid w-[min(90vw,17em)] shrink-0 snap-center rounded-sm bg-surface/contrast p-sm sm:w-[18em] md:snap-start"
      {...storyblokEditable(blok)}
    >
      <ul className={cn("t-default-md list-none")}>
        <CarbonCredisSectionListItem
          value={blok.requirement1}
          isDisabled={Number(blok.disabledRequirementsCount) > 3}
        />

        <CarbonCredisSectionListItem
          value={blok.requirement2}
          isDisabled={Number(blok.disabledRequirementsCount) > 2}
        />

        <CarbonCredisSectionListItem
          value={blok.requirement3}
          isDisabled={Number(blok.disabledRequirementsCount) > 1}
        />

        <CarbonCredisSectionListItem
          value={blok.requirement4}
          isDisabled={Number(blok.disabledRequirementsCount) > 0}
        />
      </ul>

      <div className="flex flex-col gap-sm p-sm">
        <h1 className="t-prosi-xl text-content-heading">{blok.title}</h1>

        <p className="t-strong-xl text-balance text-content-heading">
          {blok.text}
        </p>
      </div>
    </Rails.Item>
  );
}

type CarbonCredisSectionListItemProps = {
  value: string;
  isDisabled?: boolean;
};

function CarbonCredisSectionListItem({
  value,
  isDisabled,
}: CarbonCredisSectionListItemProps) {
  return (
    <li
      className={cn(
        "flex items-center gap-2xs border-border/200 border-b p-sm",
        isDisabled && "opacity-[.5]",
      )}
    >
      <Icon
        name={isDisabled ? "XCircle" : "CheckCircle"}
        className="inline shrink-0 text-[1.6em]"
      />
      {value}
    </li>
  );
}

type HowToSellItemProps = {
  blok: CarbonCreditsSectionListItemBlok;
} & PageContext;

export function HowToSellItem({ blok, ...context }: HowToSellItemProps) {
  return (
    <li
      {...storyblokEditable(blok as SbBlokData)}
      className="flex items-center gap-sm border-border/200 border-b py-sm md:p-sm"
    >
      <Icon
        name={blok.icon}
        className="circle-icon shrink-0"
        weight="duotone"
      />
      <RichText data={blok.text} className="t-strong-lg" {...context} />
    </li>
  );
}
