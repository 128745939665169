import { type SbBlokData, storyblokEditable } from "@storyblok/react/rsc";

import { Button, Icon } from "~components/ui";
import { type PageContext, getLinkProps } from "~lib/storyblok";
import { cn } from "~utils";

import { RichText } from "../rich-text";
import type { BenefitsSectionBlok, BenefitsSectionItemBlok } from "./types";

type BenefitsSectionProps = {
  blok: BenefitsSectionBlok;
} & PageContext;

export function BenefitsSection({ blok, ...context }: BenefitsSectionProps) {
  return (
    <section
      id={blok.sectionId}
      data-section-type="benefits"
      className={cn(
        blok.theme,
        "section bg-surface/200 text-content-heading",
        blok.backgroundVariant === "darker" && "bg-surface/300",
        blok.backgroundVariant === "accent" && "bg-surface/accent",
        blok.backgroundVariant === "lighter" && "bg-surface/100",
        blok.leading && "gap-sm",
      )}
      {...storyblokEditable(blok)}
    >
      {blok.leading && (
        <h3 className="t-prosi-xl max-w-leading">{blok.leading}</h3>
      )}
      <h1 className="t-strong-3xl max-w-title/sm text-balance">{blok.title}</h1>

      <div className="grid max-w-text place-items-start gap-lg">
        {blok.topSubtitle && (
          <RichText
            data={blok.topSubtitle}
            classNames={{
              paragraph: "t-prose-lg",
              heading: "t-prosi-2xl",
            }}
            {...context}
          />
        )}

        <ul className={cn(blok.twoColumns && "grid sm:grid-cols-2")}>
          {blok.topItems.map(item => (
            <BenefitsItem
              key={item._uid}
              blok={item}
              sideBySide={!blok.twoColumns}
              {...context}
            />
          ))}
        </ul>

        {blok.topCtaLink?.linktype && (
          <Button
            variant="secondary"
            arrow
            {...getLinkProps(blok.topCtaLink, context)}
          >
            {blok.topCtaText}
          </Button>
        )}
      </div>

      {blok.enableBottomSection ? (
        <div className="grid max-w-text place-items-start gap-lg place-self-end">
          {blok.bottomSubtitle && (
            <RichText
              data={blok.bottomSubtitle}
              classNames={{
                paragraph: "t-prose-lg",
                heading: "t-prosi-2xl",
              }}
              {...context}
            />
          )}

          <ul className={cn(blok.twoColumns && "grid sm:grid-cols-2")}>
            {blok.bottomItems?.map(item => (
              <BenefitsItem
                key={item._uid}
                blok={item}
                sideBySide={!blok.twoColumns}
                {...context}
              />
            ))}
          </ul>

          {blok.bottomCtaLink?.linktype && (
            <Button
              variant="secondary"
              arrow
              {...getLinkProps(blok.bottomCtaLink, context)}
            >
              {blok.bottomCtaText}
            </Button>
          )}
        </div>
      ) : null}
    </section>
  );
}

type BenefitsItemProps = {
  blok: BenefitsSectionItemBlok;
  sideBySide: boolean;
} & PageContext;

export function BenefitsItem({
  blok,
  sideBySide,
  ...context
}: BenefitsItemProps) {
  return (
    <li
      className={cn(
        "flex gap-sm border-border/200 border-b py-sm text-content-heading sm:px-sm",
        sideBySide ? "items-center" : "items-start",
      )}
      {...storyblokEditable(blok as SbBlokData)}
    >
      {sideBySide && (
        <Icon name={blok.icon} weight="duotone" className="circle-icon" />
      )}
      <div className="flex flex-col gap-2xs">
        {!sideBySide && (
          <Icon
            name={blok.icon}
            weight="duotone"
            className="circle-icon mb-2xs"
          />
        )}
        <RichText data={blok.text} className="t-strong-lg" {...context} />
        {blok.description && (
          <RichText
            data={blok.description}
            className="t-prose-md"
            {...context}
          />
        )}
      </div>
    </li>
  );
}
