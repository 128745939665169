import(/* webpackMode: "eager" */ "/app/apps/dotcom/public/flags/bg.svg");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/public/flags/cz.svg");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/public/flags/de.svg");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/public/flags/dk.svg");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/public/flags/ee.svg");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/public/flags/es.svg");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/public/flags/fi.svg");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/public/flags/fr.svg");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/public/flags/hu.svg");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/public/flags/lt.svg");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/public/flags/lv.svg");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/public/flags/no.svg");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/public/flags/pl.svg");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/public/flags/ro.svg");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/public/flags/ua.svg");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/public/flags/uk.svg");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/public/flags/us.svg");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/src/app/globals.css");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/src/components/cms/storyblok-provider.tsx");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/src/components/env-provider.tsx");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/src/lib/ampli/provider.tsx");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/src/lib/tracking/utm-provider.tsx");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/src/utils/device.ts");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/src/utils/screen.ts");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/src/utils/scroll.ts");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/src/utils/tracking.ts");
import(/* webpackMode: "eager" */ "/app/node_modules/@storyblok/react/dist/bridge-loader.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@storyblok/react/dist/story.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/overpass.woff2\",\"variable\":\"--font-sans\",\"weight\":\"100 900\"}],\"variableName\":\"Overpass\"}");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/source-serif-4-regular.woff2\",\"variable\":\"--font-serif-regular\",\"style\":\"normal\"}],\"variableName\":\"SourceSerif4Regular\"}");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/source-serif-4-italic.woff2\",\"variable\":\"--font-serif-italic\",\"style\":\"italic\"}],\"variableName\":\"SourceSerif4Italic\"}")