"use client";

import { renderRichText, storyblokEditable } from "@storyblok/react/rsc";
import { type FormEventHandler, useState } from "react";
import { Button } from "~components/ui";
import { SelectField, TextField, TextareaField } from "~components/ui/fields";
import { type PageContext, unwrapParagraph } from "~lib/storyblok";
import { cn } from "~utils";
import type { ContactSectionBlok } from "./types";

type ContactSectionProps = {
  blok: ContactSectionBlok;
} & PageContext;

export function ContactSection({ blok, ...context }: ContactSectionProps) {
  const [isSuccess, setIsSuccess] = useState(false);
  const text = unwrapParagraph(renderRichText(blok.text));
  const successText = unwrapParagraph(renderRichText(blok.successText));

  const submit: FormEventHandler<HTMLFormElement> = async ev => {
    ev.preventDefault();
    ev.stopPropagation();

    const formData = Object.fromEntries(new FormData(ev.currentTarget));

    try {
      await fetch("/api/contact", {
        method: "POST",
        body: JSON.stringify({ ...formData, marketId: context.marketId }),
      });

      setIsSuccess(true);
    } catch (err) {
      alert("An error has occured. Please reach out using an email client.");
    }
  };

  return (
    <section
      id={blok.sectionId}
      data-section-type="contact"
      className={cn(blok.theme, "section place-items-center")}
      {...storyblokEditable(blok)}
    >
      {!isSuccess ? (
        <form
          method="post"
          action="/api/contact"
          className="grid max-w-[34em] grid-cols-1 gap-sm-md rounded-lg bg-surface/contrast px-sm py-xl-2xl md:grid-cols-2 md:px-lg-xl"
          target="_self"
          onSubmit={submit}
        >
          <div className="grid gap-sm md:col-span-2">
            <h1 className="t-strong-3xl max-w-title text-balance text-content-heading">
              {blok.title}
            </h1>

            {text && (
              <p
                // biome-ignore lint/security/noDangerouslySetInnerHtml: CMS is safe
                dangerouslySetInnerHTML={{ __html: text }}
                className="t-default-md max-w-text text-pretty leading-normal"
              />
            )}
          </div>

          <TextField name="name" required label={blok.nameLabel} />

          <TextField name="company" required label={blok.companyLabel} />

          <TextField
            name="email"
            required
            type="email"
            label={blok.emailLabel}
          />

          <TextField name="phone" required type="tel" label={blok.phoneLabel} />

          <SelectField
            name="topic"
            required
            label={blok.topicLabel}
            className="md:col-span-2"
          >
            {blok.topics.map(topic => (
              <option key={topic} value={topic}>
                {getTopicLabel(topic, blok)}
              </option>
            ))}
          </SelectField>

          <TextareaField
            name="message"
            required
            label={blok.messageLabel}
            className="md:col-span-2"
          />

          {/* FAKE FIELD: Honeypot for spam, address is not part of the form */}
          <TextField
            className="sr-only"
            aria-hidden="true"
            name="address"
            label="Address"
          />

          <Button type="submit" className="place-self-end md:col-span-2">
            {blok.submitText}
          </Button>
        </form>
      ) : (
        <div className="grid max-w-[34em] gap-sm rounded-lg bg-surface/contrast px-sm py-xl-2xl md:px-lg-xl">
          <h2 className="t-strong-2xl">{blok.successTitle}</h2>

          {successText && (
            <p
              // biome-ignore lint/security/noDangerouslySetInnerHtml: CMS is safe
              dangerouslySetInnerHTML={{ __html: successText }}
              className="t-default-md max-w-text text-pretty leading-normal"
            />
          )}
        </div>
      )}
    </section>
  );
}

function getTopicLabel(topic: string, blok: ContactSectionBlok): string {
  const lookup: Record<string, string> = {
    "farmer-sales": blok.farmerSalesLabel,
    "carbon-credits": blok.carbonCreditsLabel,
    "climate-solutions": blok.climateSolutionsLabel,
    "platform-support": blok.platformSupportLabel,
    "service-partnership": blok.servicePartnershipLabel,
    "join-the-team": blok.joinTheTeamLabel,
    "media-and-press": blok.mediaAndPressLabel,
    investors: blok.investorsLabel,
    "issue-or-complaint": blok.issueOrComplaintLabel,
    other: blok.otherLabel,
  };

  return lookup[topic] ?? topic;
}
