"use client";

import { type SbBlokData, storyblokEditable } from "@storyblok/react/rsc";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import Image from "next/image";
import { type RefObject, createRef, useEffect, useMemo } from "react";

import { Icon } from "~components/ui";
import {
  type PageContext,
  buildImageLoader,
  getImageDimensions,
  imageLoader,
} from "~lib/storyblok";
import { cn, useScreenSize } from "~utils";

import { RichText } from "../rich-text";
import type { CropDropSectionBlok } from "./types";

type CropDropSectionProps = {
  blok: CropDropSectionBlok;
} & PageContext;

export function CropDropSection({ blok, ...context }: CropDropSectionProps) {
  const cloudLeftRef = createRef<HTMLImageElement>();
  const cloudRightRef = createRef<HTMLImageElement>();
  const sectionRef = createRef<HTMLDivElement>();
  const textRef = createRef<HTMLDivElement>();
  const backgroundImageRef = createRef<HTMLDivElement>();

  const { isMobile } = useScreenSize();

  const title = useMemo(
    () => (isMobile ? blok.titleMobile || blok.title : blok.title),
    [blok, isMobile],
  );

  useAnimations({
    cloudLeftRef,
    cloudRightRef,
    sectionRef,
    textRef,
    backgroundImageRef,
  });

  return (
    <section
      ref={sectionRef}
      id={blok.sectionId}
      data-section-type="crop-drop"
      className={cn(
        blok.theme,
        // Use section, with some adjustments
        "section relative grid-rows-[1fr_auto] place-items-center pb-[0] max-md:gap-y-[0]",
        "relative z-0 min-h-[100vh] w-full bg-gradient-to-b from-surface/300 to-surface/200",
      )}
      {...storyblokEditable(blok as SbBlokData)}
    >
      {/* Wrapper is needed here, so the clouds don't create horizontal overflow */}
      <div className="section-item-full absolute top-[0] h-full overflow-hidden">
        {blok.cloudImage.filename && (
          <>
            <Image
              ref={cloudLeftRef}
              src={blok.cloudImage.filename}
              {...getImageDimensions(blok.cloudImage)}
              loader={imageLoader}
              alt={blok.cloudImage.alt}
              className="-left-[25%] absolute top-[0] z-0 max-w-[70%]"
            />

            <Image
              ref={cloudRightRef}
              src={blok.cloudImage.filename}
              {...getImageDimensions(blok.cloudImage)}
              loader={imageLoader}
              alt={blok.cloudImage.alt}
              className="-right-[10%] md:-right-[20%] absolute top-[60%] z-0 max-w-[60%] opacity-50 md:top-1/3 md:max-w-[40%]"
            />
          </>
        )}
      </div>

      <div
        ref={textRef}
        className="flex max-w-[34em] flex-col place-items-center gap-sm overflow-hidden text-center md:sticky md:top-[var(--section-py)]"
      >
        {blok.icon && (
          <Icon name={blok.icon} weight="duotone" className="circle-icon" />
        )}

        <h1 className="t-strong-4xl text-balance">{title}</h1>

        <RichText
          data={blok.text}
          className="t-strong-lg text-pretty"
          {...context}
        />
      </div>

      {blok.backgroundImage.filename && (
        <div
          className="section-item-full relative z-10 self-end max-md:translate-y-[10%]"
          ref={backgroundImageRef}
        >
          <Image
            sizes="100vw"
            src={blok.backgroundImage.filename}
            width={750}
            height={700}
            loader={buildImageLoader({
              aspectRatio: 750 / 700,
            })}
            alt={blok.backgroundImage.alt}
            className="w-full bg-gradient-to-b from-[transparent] to-20% to-surface/200 md:hidden"
          />

          <Image
            sizes="100vw"
            src={blok.backgroundImage.filename}
            width={1480}
            height={500}
            loader={buildImageLoader({
              aspectRatio: 1480 / 676,
            })}
            alt={blok.backgroundImage.alt}
            className="w-full bg-gradient-to-b from-[transparent] to-20% to-surface/200 lg:hidden max-md:hidden"
          />

          <Image
            sizes="100vw"
            src={blok.backgroundImage.filename}
            width={2000}
            height={557}
            quality={40}
            loader={buildImageLoader({
              aspectRatio: 2000 / 557,
            })}
            alt={blok.backgroundImage.alt}
            className="w-full bg-gradient-to-b from-[transparent] to-20% to-surface/200 max-lg:hidden"
          />

          <div
            className={cn(
              // 1px negative overlap is needed to avoid "gap" between pattern and next section
              "-bottom-[1px] absolute h-full w-full bg-surface/200",
              "[mask-image:url(/masks/seed-wave-2.svg)] [mask-position:bottom_right] [mask-size:max(100%,70em)_auto]",
            )}
            style={{
              background: blok.patternColor || undefined,
            }}
          />
        </div>
      )}
    </section>
  );
}

type UseCloudsAnimationProps = {
  cloudLeftRef: RefObject<HTMLImageElement>;
  cloudRightRef: RefObject<HTMLImageElement>;
  sectionRef: RefObject<HTMLElement>;
  textRef: RefObject<HTMLElement>;
  backgroundImageRef: RefObject<HTMLElement>;
};

function useAnimations({
  cloudLeftRef,
  cloudRightRef,
  sectionRef,
  textRef,
  backgroundImageRef,
}: UseCloudsAnimationProps) {
  const { isMobile } = useScreenSize();

  useEffect(() => gsap.registerPlugin(ScrollTrigger), []);

  useEffect(() => {
    if (
      !cloudLeftRef.current ||
      !cloudRightRef.current ||
      !textRef.current ||
      !backgroundImageRef.current
    ) {
      return;
    }

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: sectionRef.current,
        // From when the top of the section hits the top of the screen.
        start: "top top",
        // ...until the bottom of the section hits the center of the screen. (the bottom edge starts outside the screen)
        end: "bottom center",
        scrub: true,
        pinType: "transform",
        markers: false,
      },
    });

    // Slightly scale and reposition the left side cloud...
    timeline.to(
      cloudLeftRef.current,
      {
        yPercent: 10,
        xPercent: 2,
        scale: 1.05,
      },
      0,
    );

    // ...and reposition the smaller right cloud (looks further away, should have slower/smaller movement)
    timeline.to(
      cloudRightRef.current,
      {
        yPercent: -5,
        xPercent: 5,
      },
      0,
    );

    /**
     * On mobile we move the background image up, instead of scrolling the text behind it.
     * This is because the aspect ratio is different (narrower screens -> more lines of text),
     * and the effect would be too disruptive on mobile.
     */
    if (isMobile) {
      timeline.fromTo(
        backgroundImageRef.current,
        {
          yPercent: 10,
        },
        {
          yPercent: 0,
          ease: "Power2.in",
        },
        0,
      );
    }

    // Fade the text out on desktop as it scrolls behind background.
    if (!isMobile) {
      timeline.to(
        textRef.current,
        {
          opacity: 0,
          ease: "Power2.in",
          duration: 0.35,
        },
        0,
      );
    }

    return () => {
      timeline.kill();
    };
  }, [
    isMobile,
    cloudLeftRef,
    cloudRightRef,
    sectionRef,
    textRef,
    backgroundImageRef,
  ]);
}
