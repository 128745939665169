"use client";

import { type TextareaHTMLAttributes, useState } from "react";
import { cn } from "~utils";

import styles from "./textarea-field.module.css";

type TextareaFieldProps = {
  label: string;
} & Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, "rows">;

export function TextareaField({
  label,
  className,
  ...rest
}: TextareaFieldProps) {
  const [isEmpty, setIsEmpty] = useState(!rest.value);

  return (
    <div
      className={cn(styles.root, className)}
      data-state={isEmpty ? "empty" : undefined}
    >
      <label htmlFor={rest.id ?? undefined}>{label}</label>

      <div className={styles.wrapper}>
        <textarea
          rows={1}
          onInput={ev => {
            setIsEmpty(!ev.currentTarget.value);
            ev.currentTarget.style.height = "auto";
            ev.currentTarget.style.height = `${ev.currentTarget.scrollHeight}px`;
          }}
          {...rest}
        />
      </div>
    </div>
  );
}
