import { storyblokEditable } from "@storyblok/react/rsc";

import { cn } from "~utils";

import { MigrationPersonDetailsCard } from "./migration-person-details-card";
import type { MigrationPersonDetailsCardsSectionBlok } from "./types";

type MigrationPersonDetailsCardsSectionProps = {
  blok: MigrationPersonDetailsCardsSectionBlok;
};

export function MigrationPersonDetailsCardsSection({
  blok,
}: MigrationPersonDetailsCardsSectionProps) {
  return (
    <section
      id={blok.sectionId}
      data-section-type="migration-person-details-cards"
      className={cn(
        blok.theme,
        "flex w-full flex-col gap-lg-xl bg-surface/200 px-sm py-2xl md:px-lg-xl",
      )}
      {...storyblokEditable(blok)}
    >
      <h1 className="t-strong-3xl text-center text-content-heading">
        {blok.title}
      </h1>

      <div className="flex flex-col items-stretch justify-center gap-lg md:flex-row">
        {blok.cards?.map(nestedBlok => (
          <MigrationPersonDetailsCard blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </div>
    </section>
  );
}
