import { cn } from "~utils";

type LoadingProps = {
  className?: string;
  dotClassName?: string;
};

export default function Loading({ dotClassName, className }: LoadingProps) {
  return (
    <div className={className}>
      <div className="flex w-[5em] items-center justify-around">
        <div
          className={cn(
            "h-sm w-sm animate-bounce rounded-full bg-sky-accent",
            dotClassName,
          )}
        />
        <div
          className={cn(
            "h-sm w-sm animate-bounce rounded-full bg-sky-accent [animation-delay:0.15s]",
            dotClassName,
          )}
        />
        <div
          className={cn(
            "h-sm w-sm animate-bounce rounded-full bg-sky-accent [animation-delay:0.3s]",
            dotClassName,
          )}
        />
      </div>
    </div>
  );
}
