"use client";

import { storyblokEditable } from "@storyblok/react/rsc";
import Image from "next/image";

import { useState } from "react";
import { Icon } from "~components/ui";
import { buildImageLoader, getFilenameDimensions } from "~lib/storyblok";
import { cn } from "~utils";
import type { MigrationPersonDetailsCardBlok } from "./types";

type MigrationPersonDetailsCardProps = {
  blok: MigrationPersonDetailsCardBlok;
};

export function MigrationPersonDetailsCard({
  blok,
}: MigrationPersonDetailsCardProps) {
  const [isPosterClicked, setIsPosterClicked] = useState(false);

  return (
    <section
      className="flex w-full flex-1 flex-col rounded-sm bg-surface/contrast md:max-w-[20em]"
      {...storyblokEditable(blok)}
    >
      <div>
        {isPosterClicked ? (
          // biome-ignore lint/a11y/useMediaCaption: skipped becuase ofmigration
          <video
            className="w-full rounded-t-sm"
            controls
            autoPlay
            preload="none"
          >
            <source src={blok.video.filename ?? undefined} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          // biome-ignore lint/a11y/useKeyWithClickEvents: skipped becuase ofmigration
          <div
            className="grid w-full cursor-pointer grid-cols-1"
            onClick={() => setIsPosterClicked(true)}
          >
            <Image
              className="col-start-1 row-start-1 overflow-hidden rounded-t-sm"
              {...getFilenameDimensions(blok.videoPoster.filename)}
              sizes="(max-width: 767px) 100vw, 400px"
              src={blok.videoPoster.filename ?? ""}
              loader={buildImageLoader({
                aspectRatio: 16 / 9,
                focus: blok.videoPoster.focus,
              })}
              alt={blok.videoPoster.alt ?? ""}
              title={blok.videoPoster.title ?? ""}
            />
            <div className="col-start-1 row-start-1 flex items-center justify-center">
              <Icon
                name="PlayCircle"
                className="text-[4em] text-decor/white"
                weight="regular"
              />
            </div>
          </div>
        )}
      </div>

      <div className="flex h-full flex-col justify-between gap-sm p-sm">
        <h1
          className={cn(
            "t-strong-lg text-content-heading",
            `after:content-['”'] before:content-['“']`,
          )}
        >
          {blok.text}
        </h1>

        <div className="justify-between">
          <div className="t-prose-md font-semibold text-content-heading">
            {blok.name}
          </div>

          <div className="t-prose-md text-content-heading">{blok.position}</div>
        </div>
      </div>
    </section>
  );
}
