import { type SbBlokData, storyblokEditable } from "@storyblok/react/rsc";
import Image from "next/image";

import { Icon } from "~components/ui";
import {
  type PageContext,
  buildImageLoader,
  getLinkProps,
} from "~lib/storyblok";
import { cn } from "~utils";

import { RichText } from "../rich-text";
import type { LinkSectionBlok, LinkSectionItemBlok } from "./types";

type LinkSectionProps = {
  blok: LinkSectionBlok;
} & PageContext;

export function LinkSection({ blok, ...context }: LinkSectionProps) {
  return (
    <section
      id={blok.sectionId}
      data-section-type="link"
      className={cn(
        blok.theme,
        "section gap-[0]",
        blok.theme === "section-satellite" && "bg-surface/200",
      )}
      {...storyblokEditable(blok)}
    >
      <div className="relative z-10 flex flex-col gap-2xl">
        <div className="flex flex-col gap-md text-pretty">
          <h1 className="t-strong-3xl max-w-title/sm text-balance text-content-heading">
            {blok.title}
          </h1>

          <RichText
            data={blok.text}
            {...context}
            className="t-strong-lg max-w-sub text-pretty text-content-text"
          />
        </div>

        <ul className="grid max-w-[40em] place-content-start">
          {blok.items.map(item => (
            <LinkSectionItem key={item._uid} blok={item} {...context} />
          ))}
        </ul>
      </div>

      {blok.backgroundImage.filename && (
        <Image
          width="975"
          height="832"
          sizes="(max-width: 639px) 100vw, 975px"
          src={blok.backgroundImage.filename}
          loader={buildImageLoader({
            aspectRatio: 975 / 832,
            focus: blok.backgroundImage.focus,
          })}
          alt={blok.backgroundImage.alt ?? ""}
          className={cn(
            "-mb-[var(--section-py)] z-0 w-full place-self-end",
            "[mask-image:url(/masks/seed-1.svg)] [mask-mode:luminance]",

            // Mobile
            "[mask-position:right_0%_top_-10%] [mask-size:140%_auto]",
            "-mt-lg max-md:-mx-[var(--section-px)] max-md:w-screen max-md:max-w-[100vw]",

            // Tablet
            "md:[mask-position:right_top] md:[mask-size:100%_auto]",
            "md:-mt-[10%] md:w-[90%]",

            // Desktop
            "xl:-mr-2xl xl:-mt-[33%] xl:w-[85%]",
          )}
        />
      )}
    </section>
  );
}

type LinkSectionItemProps = {
  blok: LinkSectionItemBlok;
} & PageContext;

export function LinkSectionItem({ blok, ...context }: LinkSectionItemProps) {
  return (
    <li
      {...storyblokEditable(blok as SbBlokData)}
      className={cn(
        "group relative border-border/200 border-b text-content-heading transition-[color] hover:text-content-link",
        "after:absolute after:bottom-[-2px] after:h-[2px] after:w-full after:origin-left after:scale-x-0 after:bg-content-link",
        "hover:after:block hover:after:scale-x-100 after:transition-transform",
      )}
    >
      <a
        className="flex items-center gap-sm p-sm pl-[0] md:pl-sm"
        {...getLinkProps(blok.ctaLink, context)}
      >
        <Icon
          name={blok.icon}
          className="circle-icon origin-center transition-transform group-hover:scale-105"
          weight="duotone"
        />

        <div className="flex flex-col gap-3xs">
          <h3 className="t-strong-lg max-w-title text-balance">{blok.title}</h3>

          <p className="t-default-sm max-w-text text-pretty">{blok.text}</p>
        </div>

        <div className="ml-auto flex items-center text-strong-xl">
          <Icon name="ArrowRight" />
        </div>
      </a>
    </li>
  );
}
