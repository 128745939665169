import Image, { type StaticImageData } from "next/image";
import CertificateImage from "src/components/cms/migration-hero-section/assets/certificate.svg";
import Content360degImage from "src/components/cms/migration-hero-section/assets/content-360deg.svg";
import EarnImage from "src/components/cms/migration-hero-section/assets/earn.svg";
import EcologyImage from "src/components/cms/migration-hero-section/assets/ecology.svg";
import GlobePlantImage from "src/components/cms/migration-hero-section/assets/globe-plant.png";
import HandPlantImage from "src/components/cms/migration-hero-section/assets/hand-plant.png";
import MoneyGrowthImage from "src/components/cms/migration-hero-section/assets/money-growth.svg";
import NgoImage from "src/components/cms/migration-hero-section/assets/ngo.svg";
import RegulatorImage from "src/components/cms/migration-hero-section/assets/regulator.svg";
import SatelliteImage from "src/components/cms/migration-hero-section/assets/satellite.svg";
import TractorImage from "src/components/cms/migration-hero-section/assets/tractor.svg";
import { cn } from "~utils";
import type { ImageName } from "./types";

type Props = {
  title?: string;
  text?: string;
  imageName?: ImageName;
  statsCount: number;
};

const StatsItem = ({ imageName, title, text, statsCount }: Props) => {
  const imageByName: Record<string, string | StaticImageData> = {
    certificate: CertificateImage,
    satellite: SatelliteImage,
    money: MoneyGrowthImage,
    "360deg": Content360degImage,
    "globe-plant": GlobePlantImage,
    "hand-plant": HandPlantImage,
    earn: EarnImage,
    ecology: EcologyImage,
    ngo: NgoImage,
    regulator: RegulatorImage,
    tractor: TractorImage,
  };

  const image = imageName && imageByName[imageName];

  return (
    <div
      className={cn(
        "flex flex-col items-center gap-xs-sm",
        statsCount === 5 ? "lg:w-[30%] sm:w-[40%]" : "min-w-3xs max-w-4xl",
      )}
    >
      {image && (
        <Image
          src={image}
          width={64}
          height={64}
          className="h-[64px] w-auto"
          alt="Agreena icon"
          style={{
            filter: "invert(100%)",
          }}
        />
      )}

      <h3
        className={cn(
          "text-center",
          statsCount === 5 ? "t-strong-lg" : "t-strong-2xl",
        )}
      >
        {title}
      </h3>

      <p
        className={cn(
          "t-strong-md text-balance text-center font-semibold leading-7",
          statsCount < 5 && "max-w-[14em]",
        )}
      >
        {text}
      </p>
    </div>
  );
};

export default StatsItem;
