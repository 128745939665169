import { type ISbRichtext, storyblokEditable } from "@storyblok/react/rsc";
import Image from "next/image";
import type { ReactNode } from "react";
import {
  MARK_LINK,
  NODE_HEADING,
  NODE_IMAGE,
  NODE_PARAGRAPH,
  NODE_QUOTE,
} from "storyblok-rich-text-react-renderer";

import { SeedWave } from "~components/ui/seed-wave";
import {
  buildImageLoader,
  getFilenameDimensions,
  getLinkMarkProps,
  getReadingTime,
  imageLoader,
  isWrapperNode,
} from "~lib/storyblok";
import type { PageContext } from "~lib/storyblok/page";
import { cn, formatDate, formatMinutes } from "~utils";

import type { SectionThemes } from "~components/types";
import { BannerSection } from "../banner-section";
import type { BannerSectionBlok } from "../banner-section/types";
import { PersonSection, type PersonSectionBlok } from "../person-section";
import {
  Figure,
  type FigureBlok,
  HubspotForm,
  type HubspotFormBlok,
  type ImageGalleryBlok,
  type ListBlok,
  RichText,
} from "../rich-text";
import { ImageGallery } from "../rich-text/image-gallery";
import { List } from "../rich-text/list";
import { Tiles, type TilesBlok } from "../rich-text/tiles/tiles";
import { Video, type VideoBlok } from "../rich-text/video/video";
import type { ArticlePageBlok } from "./types";

type ArticlePageProps = {
  blok: ArticlePageBlok;
} & PageContext;

export function ArticlePage({ blok, ...context }: ArticlePageProps) {
  const readingTime = getReadingTime(blok.text);

  if (blok.isDisabled) {
    return <code className="flex p-lg">Article disabled for this market</code>;
  }

  if (blok.externalLink?.url)
    return (
      <code>
        external link:
        <br /> {blok.externalLink.url}
      </code>
    );

  return (
    <main
      {...storyblokEditable(blok)}
      data-page-type="article"
      className={cn(
        blok.theme ? blok.theme : "section-sky bg-others-white",
        blok.theme && "bg-surface/100",
        "t-prose-md text-content-text leading-relaxed",
      )}
    >
      <div className="px-xs pt-3xl-4xl pb-2xl">
        <div className="mx-auto mb-base grid w-full max-w-[44rem] gap-2xs">
          <div className="t-default-md flex place-items-center gap-[1ch]">
            <span>
              {formatDate(blok.date || context.meta.created_at, context.locale)}
            </span>
            <span className="mb-[0.5em] font-extrabold">.</span>
            <span>{formatMinutes(readingTime?.minutes, context.locale)}</span>
          </div>

          <h1 className="t-strong-3xl">{blok.title}</h1>

          {blok.author?.content?.name && (
            <div className="t-prosi-sm inline-flex place-items-center gap-2xs">
              <div className="overflow-hidden rounded-full">
                {blok.author.content.image.filename && (
                  <Image
                    width="40"
                    height="40"
                    sizes="40px"
                    loader={buildImageLoader({ aspectRatio: 1 / 1 })}
                    src={blok.author.content.image.filename}
                    alt={blok.author.content.image.alt}
                  />
                )}
              </div>

              <span>{blok.author.content.name}</span>
            </div>
          )}
        </div>

        {blok.image && (
          <div className="relative mx-auto my-lg grid max-w-[58em] overflow-hidden rounded-lg">
            {blok.image.filename && (
              <Image
                src={blok.image.filename}
                priority
                width={1600}
                height={900}
                loader={buildImageLoader({
                  aspectRatio: 16 / 9,
                  focus: blok.image.focus,
                })}
                sizes="100vw"
                alt={blok.image.alt ?? ""}
                className="col-[1/2] row-[1/3]"
              />
            )}

            {blok.pattern !== false && (
              <SeedWave
                variant="xl"
                className="section-item-full absolute top-[0] h-full w-full bg-decor/accent"
                seedPatternColor={blok.patternColor}
              />
            )}
          </div>
        )}

        <ArticlePageBody text={blok.text} theme={blok.theme} {...context} />
      </div>
    </main>
  );
}

export type ArticlePageBodyProps = {
  text: ISbRichtext;
  theme?: SectionThemes;
} & PageContext;

export function ArticlePageBody({
  text,
  theme,
  ...context
}: ArticlePageBodyProps) {
  return (
    <RichText
      {...context}
      data={text}
      className="mx-auto max-w-[44rem]"
      classNames={{
        // Gives a better scroll position when navigating directly to anchor
        anchor: "scroll-mt-md-lg",
        ordered_list:
          "mx-auto my-[1.875em] grid w-full max-w-[44rem] list-decimal gap-[0.625em] pl-[1.25em]",
        bullet_list:
          "mx-auto my-[1.875em] grid w-full max-w-[44rem] list-disc gap-[0.625em] pl-[1.25em]",
        List: "t-strong-md",
      }}
      blokResolvers={{
        Figure: props => (
          <Figure
            blok={props as FigureBlok}
            className="mx-auto my-base max-w-[48rem]"
            sizes="(min-width: 1024px) 1200px, 100vw"
            captionClassName="w-full max-w-[44rem] mx-auto"
          />
        ),
        ImageGallery: props => (
          <ImageGallery
            blok={props as ImageGalleryBlok}
            className="mx-auto my-base max-w-[48rem]"
          />
        ),
        List: props => (
          <List
            blok={props as ListBlok}
            variant="medium"
            {...context}
            className="t-default-md mx-auto my-base max-w-[44rem]"
          />
        ),
        HubspotForm: props => (
          <HubspotForm
            blok={props as HubspotFormBlok}
            {...context}
            className={cn(
              `[--hubspot-form-max-w:44rem] [--hubspot-form-px:theme("spacing.sm")]`,
              "-mx-xs last:-mb-2xl my-md-lg",
            )}
          />
        ),
        Tiles: props => (
          <Tiles
            blok={props as TilesBlok}
            className={cn(
              `[--tiles-max-w:44rem] [--tiles-px:theme("spacing.sm")]`,
              "t-default-md -mx-xs last:-mb-2xl my-md-lg gap-y-md py-lg-xl",
            )}
            {...context}
          />
        ),
        Video: props => (
          <Video
            blok={props as VideoBlok}
            className={cn(
              `[--video-max-w:44rem] [--video-px:theme("spacing.sm")]`,
              "-mx-xs last:-mb-2xl my-md-lg",
            )}
            {...context}
          />
        ),
        BannerSection: props => (
          <BannerSection
            blok={props as BannerSectionBlok}
            className="-mx-xs [--banner-max-w:66rem]"
            {...context}
          />
        ),
        PersonSection: props => (
          <PersonSection
            blok={props as PersonSectionBlok}
            className="-mx-xs [--person-section-max-w:48rem]"
            {...context}
          />
        ),
      }}
      markResolvers={{
        [MARK_LINK]: (children, props) => (
          <a {...getLinkMarkProps(props, context)}>{children}</a>
        ),
      }}
      nodeResolvers={{
        [NODE_IMAGE]: (_children, { alt, src, title }) => (
          <Image
            {...getFilenameDimensions(src)}
            sizes="(max-width: 1200px) 100vw, 940px"
            src={src ?? ""}
            loader={imageLoader}
            alt={alt ?? ""}
            title={title}
            className="mx-auto my-base w-full max-w-[48em] overflow-hidden rounded-lg"
          />
        ),
        [NODE_PARAGRAPH]: children => {
          // Unwrap elements when there is no need for extra paragraph
          if (isWrapperNode(children)) return <>{children}</>;

          return (
            <p
              className={cn(
                "mx-auto mb-[1.5em] w-full max-w-[44rem] text-pretty",
                "[&_a]:text-content-link [&_a]:underline",
              )}
            >
              {children}
            </p>
          );
        },
        [NODE_HEADING]: (children, { level }) => {
          const wrapper = (heading: ReactNode) => (
            <div className="mx-auto my-lg mb-[1em] w-full max-w-[44rem]">
              {heading}
            </div>
          );

          switch (level) {
            case 3:
              return wrapper(
                <h3
                  className={
                    "t-strong-xl max-w-title text-balance text-content-heading leading-tight"
                  }
                >
                  {children}
                </h3>,
              );
            case 4:
              return wrapper(
                <h4
                  className={
                    "t-strong-lg max-w-sub text-balance font-medium text-content-heading leading-snug"
                  }
                >
                  {children}
                </h4>,
              );
            case 5:
              return wrapper(
                <h5
                  className={
                    "t-strong-md max-w-sub text-balance font-semibold text-content-heading leading-snug"
                  }
                >
                  {children}
                </h5>,
              );
            default:
              return wrapper(
                <h2
                  className={
                    "t-strong-2xl max-w-title text-balance text-content-heading leading-tight"
                  }
                >
                  {children}
                </h2>,
              );
          }
        },
        [NODE_QUOTE]: children => (
          <blockquote className="t-prosi-lg mx-auto my-lg w-full max-w-[44rem] text-balance border-content-link border-l-2 pl-lg">
            {children}
          </blockquote>
        ),
      }}
    />
  );
}
