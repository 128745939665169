import { storyblokEditable } from "@storyblok/react";
import { Widget } from "@typeform/embed-react";

import { ampli } from "~lib/ampli";
import type { PageContext } from "~lib/storyblok";
import { useUtm } from "~lib/tracking";

import type { TypeformSectionBlok } from "./types";

type TypeformSectionProps = {
  blok: TypeformSectionBlok;
} & PageContext;

export function TypeformSection({ blok, searchParams }: TypeformSectionProps) {
  const { utmParams } = useUtm();

  let typeformId = blok.id;

  const isAlternateOverride = searchParams?.alternate === "true";
  const roll = Math.random() * 100;

  if (
    blok.alternateId &&
    (isAlternateOverride || roll <= (blok.alternatePercentage ?? 0))
  ) {
    typeformId = blok.alternateId;
  }

  return (
    <section data-section-type="typeform" {...storyblokEditable(blok)}>
      <Widget
        id={typeformId}
        // Enables hubspot tracking
        hubspot
        transitiveSearchParams={[
          "utm_source",
          "utm_medium",
          "utm_campaign",
          "utm_term",
          "utm_content",
          "hubspot_utk",
          "hubspot_page_name",
          "hubspot_page_url",
        ]}
        hidden={utmParams}
        className="h-screen"
        onReady={({ formId }) => ampli.typeformReady({ typeformId: formId })}
        onQuestionChanged={({ formId, ref }) =>
          ampli.typeformQuestionChanged({
            typeformId: formId,
            typeformQuestionRef: ref,
          })
        }
        onSubmit={({ formId }) =>
          ampli.typeformSubmitted({ typeformId: formId })
        }
      />
    </section>
  );
}
