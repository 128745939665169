import { storyblokEditable } from "@storyblok/react/rsc";

import { cn } from "~utils";

import { MigrationExpandableTile } from "./migration-expandable-tile";
import type { MigrationExpandableTilesSectionBlok } from "./types";

type MigrationExpandableTilesSectionProps = {
  blok: MigrationExpandableTilesSectionBlok;
};

export function MigrationExpandableTilesSection({
  blok,
}: MigrationExpandableTilesSectionProps) {
  return (
    <section
      id={blok.sectionId}
      className={cn(blok.theme, "grid gap-xl bg-surface/200 py-2xl")}
      {...storyblokEditable(blok)}
    >
      <div className="mx-auto flex max-w-body flex-col place-items-center gap-lg px-sm md:px-lg-xl">
        <h2 className="t-strong-3xl text-balance text-center text-content-heading">
          {blok.title}
        </h2>

        <p className="t-prose-md mx-auto text-balance text-center">
          {blok.text}
        </p>
      </div>

      <div className="mx-auto flex w-full max-w-screen-xl flex-col items-stretch justify-center gap-lg px-sm md:px-lg-xl">
        {blok.tiles?.map(nestedBlok => (
          <MigrationExpandableTile
            moreLabel={blok.moreLabel}
            blok={nestedBlok}
            key={nestedBlok._uid}
          />
        ))}
      </div>
    </section>
  );
}
