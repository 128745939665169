import { useEffect, useRef, useState } from "react";
import { cn } from "~utils";
import { DocPageGroupItem } from "./doc-page-group-item";
import type { DocPageGroupBlok } from "./types";

type DocPageGroupProps = {
  blok: DocPageGroupBlok;
};

export function DocPageGroup({ blok }: DocPageGroupProps) {
  const [selected, setSelected] = useState(blok.items[0]);
  const groupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!groupRef.current) return;

    const searchedAnchor = window.location.hash.substring(1); // Get anchor from url and remove '#'
    const searchedItemAttempt = blok.items.find(
      i => searchedAnchor === i.anchorId,
    ); // Try finding an item with anchor

    // If group is not searched, check if item is
    if (searchedAnchor !== blok.anchorId && searchedItemAttempt) {
      setSelected(searchedItemAttempt); // Switch to searched item
      groupRef.current.scrollIntoView({ behavior: "instant" }); // Scroll to group
    }
  }, [blok]);

  return (
    <section
      id={blok.anchorId}
      data-section-type="doc-group"
      className="py-xl"
      ref={groupRef}
    >
      <h1 className="t-strong-2xl mb-lg text-sand-300">{blok.title}</h1>
      <div className="border-b-[1px] border-b-sand-300/30">
        {blok.items.map(menuItem => {
          return (
            <div
              key={menuItem.anchorId}
              onClick={() => setSelected(menuItem)}
              onKeyDown={() => setSelected(menuItem)}
              className={cn(
                "t-prose-xs mr-2xs inline-block rounded-t-sm px-xs py-2xs hover:cursor-pointer",
                {
                  "bg-sand-300 text-others-white":
                    menuItem.title === selected.title,
                },
              )}
            >
              {menuItem.title}
            </div>
          );
        })}
      </div>
      {selected && <DocPageGroupItem blok={selected} />}
    </section>
  );
}
