"use client";

import { storyblokEditable } from "@storyblok/react/rsc";
import Image from "next/image";

import {
  type PageContext,
  buildImageLoader,
  getLinkProps,
} from "~lib/storyblok";
import { cn } from "~utils";
import { getSurfaceVariant } from "~utils/style";

import { Button } from "~components/ui";
import { RichText } from "../rich-text";
import type { BannerSectionBlok } from "./types";

type BannerSectionProps = {
  blok: BannerSectionBlok;
  className?: string;
} & PageContext;

export function BannerSection({
  blok,
  className,
  ...context
}: BannerSectionProps) {
  return (
    <>
      <aside
        className={cn(
          `[--banner-max-w:66rem] [--banner-px:theme("spacing.sm")]`,
          "flex place-content-center px-[var(--banner-px)]",
          blok.topPad === "overlap" && "-mt-md-lg",
          blok.topPad === "minimal" && "pt-md-lg",
          (!blok.topPad || blok.topPad === "default") && "pt-lg-xl",
          blok.bottomPad === "overlap" && "-mb-md-lg",
          blok.bottomPad === "minimal" && "pb-md-lg",
          (!blok.bottomPad || blok.bottomPad === "default") && "pb-lg-xl",
          blok.backgroundTheme,
          getSurfaceVariant(blok.backgroundVariant),
          className,
        )}
        {...storyblokEditable(blok)}
      >
        <div
          className={cn(
            "flex w-full max-w-[var(--banner-max-w)] items-center gap-md-lg rounded-md p-sm-md text-content-text max-md:flex-col md:p-md-lg md:pr-xl-2xl max-md:text-center",
            blok.bannerTheme,
            getSurfaceVariant(blok.bannerVariant),
          )}
        >
          {blok.image?.filename && (
            <Image
              src={blok.image.filename}
              width={100}
              height={100 / (4 / 5)}
              sizes="120"
              loader={buildImageLoader({
                aspectRatio: 4 / 5,
                focus: blok.image.focus,
              })}
              alt={blok.image.alt}
              className="shrink-0"
            />
          )}

          <div className="flex grow flex-col place-content-center gap-xs">
            {blok.leading && (
              <h3 className="-mb-xs t-prosi-md max-w-sub text-pretty text-content-heading leading-tight">
                {blok.leading}
              </h3>
            )}
            {blok.title && (
              <h2 className="t-strong-xl max-w-title text-balance text-content-heading leading-snug">
                {blok.title}
              </h2>
            )}

            {blok.text && (
              <RichText
                className="t-strong-md max-w-text text-pretty"
                data={blok.text}
                {...context}
              />
            )}
          </div>

          {blok.ctaLink && (
            <Button
              {...getLinkProps(blok.ctaLink, context)}
              className="shrink-0"
            >
              {blok.ctaText}
            </Button>
          )}
        </div>
      </aside>
    </>
  );
}
