import { storyblokEditable } from "@storyblok/react/rsc";
import { Button, SeedWave, VideoPlayer } from "~components/ui";
import { type PageContext, getLinkProps, getPosterUrl } from "~lib/storyblok";
import { cn, useScreenSize } from "~utils";
import { getSurfaceVariant } from "~utils/style";
import { RichText, isTextEmpty } from "../rich-text";
import type { VideoSectionBlok } from "./types";

type VideoSectionProps = {
  blok: VideoSectionBlok;
} & PageContext;

export function VideoSection({ blok, ...context }: VideoSectionProps) {
  const { isMobile } = useScreenSize();

  return (
    <section
      id={blok.sectionId}
      className={cn(
        "section relative",
        blok.theme,
        getSurfaceVariant(blok.backgroundVariant),
      )}
      {...storyblokEditable(blok)}
    >
      {blok.pattern ? (
        <SeedWave
          className="section-item-full absolute bottom-[0] z-0 h-full w-full bg-decor/accent"
          seedPatternColor={blok.patternColor}
        />
      ) : null}

      {(blok.title || !isTextEmpty(blok.text)) && (
        <div className="z-10 flex flex-col place-items-start gap-sm">
          {blok.leading && (
            <h2 className="-mb-2xs t-prosi-lg max-w-[45ch] text-pretty text-content-heading leading-tight">
              {blok.leading}
            </h2>
          )}

          {blok.title && (
            <h1 className="t-strong-3xl max-w-title/sm text-balance text-content-heading">
              {blok.title}
            </h1>
          )}

          {blok.text && (
            <RichText
              className="t-strong-lg max-w-sub text-pretty"
              data={blok.text}
              {...context}
            />
          )}

          {blok.ctaText && blok.ctaLink && (
            <Button {...getLinkProps(blok.ctaLink, context)}>
              {blok.ctaText}
            </Button>
          )}
        </div>
      )}

      {!blok.youtubeId && blok.video?.filename && (
        <VideoPlayer
          src={blok.video.filename}
          captions={blok.captions}
          poster={
            blok.poster
              ? getPosterUrl({
                  image: blok.poster,
                  width: isMobile ? 600 : 1280,
                })
              : undefined
          }
          {...context}
          className="z-10"
        />
      )}

      {blok.youtubeId && (
        <iframe
          src={`https://www.youtube.com/embed/${blok.youtubeId}`}
          title={blok.youtubeTitle}
          width={isMobile ? 600 : 1280}
          className={cn(
            "z-10 w-full overflow-hidden rounded-md",
            isMobile ? "aspect-[4/3]" : "aspect-video",
          )}
        />
      )}
    </section>
  );
}
