import { storyblokEditable } from "@storyblok/react/rsc";
import Image from "next/image";
import type { PageContext } from "~lib/storyblok";
import { getFilenameDimensions, imageLoader } from "~lib/storyblok";
import { cn } from "~utils";
import { getSurfaceVariant } from "~utils/style";
import { BannerSection, type BannerSectionBlok } from "../banner-section";
import { PersonSection, type PersonSectionBlok } from "../person-section";
import { Figure, type FigureBlok, type ListBlok, RichText } from "../rich-text";
import { List } from "../rich-text/list";
import { Video, type VideoBlok } from "../rich-text/video";
import type { TextSectionBlok } from "./types";

type TextSectionProps = {
  blok: TextSectionBlok;
} & PageContext;

export function TextSection({ blok, ...context }: TextSectionProps) {
  return (
    <section
      id={blok.sectionId}
      data-section-type="text"
      className={cn(
        blok.theme,
        "section t-prose-lg w-full place-items-center text-content-text leading-relaxed",
        getSurfaceVariant(blok.backgroundVariant),
      )}
      {...storyblokEditable(blok)}
    >
      <div className="flex w-full max-w-[44rem] flex-col">
        <RichText
          data={blok.text}
          classNames={{
            h1: "t-strong-4xl-5xl mb-[0.5em] max-w-title/sm text-pretty text-content-heading",
            h2: "t-strong-3xl mb-[0.75em] max-w-title text-pretty text-content-heading",
            h3: "t-strong-2xl mb-[1em] max-w-title text-pretty text-content-heading",
            h4: "t-prosi-xl mb-xs max-w-title text-pretty text-content-heading",
            h5: "t-strong-lg mb-[1em] max-w-title text-pretty text-content-heading",
            paragraph: "mb-[1.5em]",
          }}
          nodeResolvers={{
            image: (_children, { alt, src, title }) => (
              <Image
                {...getFilenameDimensions(src)}
                sizes="(max-width: 1200px) 100vw, 940px"
                src={src ?? ""}
                loader={imageLoader}
                alt={alt ?? ""}
                title={title}
                className="-mx-sm my-base w-full overflow-hidden rounded-lg"
              />
            ),
          }}
          blokResolvers={{
            Figure: props => (
              <Figure
                blok={props as FigureBlok}
                className="-mx-sm my-base"
                sizes="(min-width: 1024px) 1200px, 100vw"
                captionClassName="mx-sm t-prosi-md"
              />
            ),
            List: props => (
              <List
                blok={props as ListBlok}
                variant="medium"
                {...context}
                className="t-prose-lg -mx-sm mb-lg"
              />
            ),
            Video: props => (
              <Video
                blok={props as VideoBlok}
                className={cn(
                  "[--video-px:0",
                  "[--video-no-text-pt:theme(spacing.xs)] [--video-py:0px]",
                  "-mx-sm my-xs mb-md-lg",
                )}
                {...context}
              />
            ),
            BannerSection: props => (
              <BannerSection
                blok={props as BannerSectionBlok}
                className={cn(
                  "[--banner-max-w:66rem] [--banner-px:0px]",
                  "md:-mx-xl-2xl lg:-mx-3xl-4xl",
                )}
                {...context}
              />
            ),
            PersonSection: props => (
              <PersonSection
                blok={props as PersonSectionBlok}
                className={cn(
                  "[--person-section-max-w:48rem] [--person-section-px:0px]",
                  "md:-mx-xl-2xl lg:-mx-3xl-4xl",
                )}
                {...context}
              />
            ),
          }}
          {...context}
        />
      </div>
    </section>
  );
}
