import { renderRichText } from "@storyblok/react";
import { Icon } from "~components/ui";
import { cn } from "~utils";
import type { DocPageGroupItemBlok } from "./types";

type DocPageGroupProps = {
  blok: DocPageGroupItemBlok;
};

export function DocPageGroupItem({ blok }: DocPageGroupProps) {
  const richText = renderRichText(blok.text);

  const renderExtras = () => {
    return (
      <>
        {!!blok.visitLink?.length && (
          <a
            href={blok.visitLink[0].url.url}
            className="t-prose-sm mt-sm mr-xs inline-block rounded-lg bg-sand-200 px-md py-xs text-others-white"
            target="_blank"
            rel="noreferrer"
          >
            {<Icon name="LinkSimple" className="mr-3xs inline" />}{" "}
            {blok.visitLink[0].label}
          </a>
        )}
        {blok.fileDownload?.filename && blok.fileDownloadLabel && (
          <a
            href={blok.fileDownload.filename}
            className="t-prose-sm mt-sm inline-block rounded-lg bg-sand-200 px-md py-xs text-others-white"
            target="_blank"
            rel="noreferrer"
          >
            {<Icon name="DownloadSimple" className="mr-3xs inline" />}{" "}
            {blok.fileDownloadLabel}
          </a>
        )}
      </>
    );
  };

  return (
    <>
      <div
        className={cn(
          "t-prose-sm pt-md",
          // Base
          "[&_*]:mb-xs [&_a]:text-sand-200 [&_b]:text-sand-200",
          // Headers
          "[&_h1]:t-strong-xl [&_h2]:t-strong-lg [&_h3]:t-strong-md [&_h1]:text-sand-200 [&_h2]:text-sand-200 [&_h3]:text-sand-200",
          // Lists
          "[&_li]:ml-base [&_li]:list-disc",
        )}
        // biome-ignore lint/security/noDangerouslySetInnerHtml: CMS is safe
        dangerouslySetInnerHTML={{ __html: richText }}
      />
      {renderExtras()}
    </>
  );
}
