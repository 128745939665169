"use client";

import { storyblokEditable } from "@storyblok/react/rsc";
import { usePathname } from "next/navigation";
import { type MouseEvent, useMemo, useState } from "react";
import { Button } from "~components/ui";
import Loading from "~components/ui/loading/loading";
import type { PageContext, SubPage } from "~lib/storyblok";
import { useSubPages } from "~lib/storyblok/api.client";
import {
  buildCategoryLink,
  getCategory,
  getCategoryFilterTranslation,
  getCategoryFilters,
  getCategoryLabelTranslation,
  sortPages,
} from "./helpers";
import NewsPageItem from "./news-page-item";
import type { NewsPageBlok, NewsPageItemType } from "./types";

type PageProps = {
  blok: NewsPageBlok;
  initialData?: SubPage<NewsPageItemType>[]; // for storybook
} & PageContext;

export function NewsPage({ blok, initialData, ...context }: PageProps) {
  const pathName = usePathname();

  if (!blok.categories || !blok.categories.length) return null;

  const { searchParams } = context;
  const searchCategory = searchParams?.category
    ? blok.categories.find(
        c => c.toLowerCase() === searchParams?.category.toLowerCase(),
      )
    : blok.categories[0];

  const [category, setCategory] = useState(searchCategory);

  const { data: subPages, isLoading } = useSubPages<NewsPageItemType>(
    context.path,
    {
      ...context,
      ...getCategoryFilters(category),
    },
    initialData,
  );

  const items = useMemo(() => sortPages(subPages), [subPages]);

  return (
    <main
      data-page-type="news"
      className="section-sky bg-others-white"
      {...storyblokEditable(blok)}
    >
      <section className="m-auto grid max-w-screen-xl gap-xl px-md pt-3xl-4xl pb-2xl-3xl">
        <h1 className="t-strong-4xl max-w-[20ch] text-balance text-content-heading">
          {blok.headline}
        </h1>
        <div className="grid gap-xl">
          <div className="flex flex-wrap gap-2xs">
            {blok.categories.map(cat => (
              <Button
                key={cat}
                variant={cat === category ? "primary" : "secondary"}
                href={buildCategoryLink(pathName, cat, context)}
                onClick={(ev: MouseEvent<HTMLButtonElement>) => {
                  ev.preventDefault();
                  setCategory(cat);
                }}
              >
                {getCategoryFilterTranslation(cat, blok)}
              </Button>
            ))}
          </div>

          {!items.length && isLoading && (
            <Loading className="mt-3xl flex w-full flex-col items-center" />
          )}

          <div className="grid grid-cols-1 place-items-start gap-x-sm gap-y-xl md:grid-cols-[repeat(auto-fill,minmax(15em,1fr))]">
            {items.map(item => (
              <NewsPageItem
                key={item.story.id}
                story={item.story}
                url={item.url}
                categoryName={getCategoryLabelTranslation(
                  getCategory(item.story.content),
                  context.config,
                )}
                {...context}
              />
            ))}
          </div>
        </div>
      </section>
    </main>
  );
}
