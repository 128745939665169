"use client";

import { storyblokEditable } from "@storyblok/react/rsc";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import { uniqBy } from "lodash";
import Image from "next/image";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button } from "~components/ui";
import { SeedWave } from "~components/ui/seed-wave";
import {
  type PageContext,
  buildImageLoader,
  getLinkProps,
} from "~lib/storyblok";
import { cn } from "~utils";
import { RichText } from "../rich-text";
import type { SlidesItemBlok, SlidesSectionBlok } from "./types";

type SlidesSectionProps = {
  blok: SlidesSectionBlok;
} & PageContext;

export function SlidesSection({ blok, ...context }: SlidesSectionProps) {
  const items = useMemo(() => fillEmptyBackgrounds(blok.items), [blok.items]);
  const [activeItem, setActiveItem] = useState(items[0]);

  return (
    <section
      data-section-type="slides"
      className={cn(blok.theme, "relative z-0 min-h-[100vh] w-full")}
      {...storyblokEditable(blok)}
    >
      <SlideBackgrounds items={items} activeItem={activeItem} />

      <div className="relative z-10">
        {items.map((item, index) => (
          <SlidesItem
            key={item._uid}
            item={item}
            isFirst={index === 0}
            isLast={index === items.length - 1}
            onActivate={setActiveItem}
            {...context}
          />
        ))}
      </div>
    </section>
  );
}

type SlidesItemProps = {
  item: SlidesItemBlok;
  isFirst: boolean;
  isLast: boolean;
  onActivate: (item: SlidesItemBlok) => void;
} & PageContext;

function SlidesItem({
  item,
  isFirst,
  isLast,
  onActivate,
  ...context
}: SlidesItemProps) {
  const ref = useRef<HTMLDivElement | null>(null);

  // Wrap the onActive with the item state, so the listener can identify it.
  const wrappedOnActivate = useCallback(() => {
    onActivate(item);
  }, [item, onActivate]);

  // Fade item in/out based on scroll position, and activate the correct background
  useEffect(() => {
    if (!ref.current || (isFirst && isLast)) return;

    gsap.registerPlugin(ScrollTrigger);

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: ref.current,
        start: "top center",
        end: "bottom center",
        scrub: true,
        preventOverlaps: true,
        onEnter: wrappedOnActivate,
        onEnterBack: wrappedOnActivate,
      },
    });

    // Animate from in to fully visible at 50% scroll, except first item.
    if (!isFirst) {
      timeline.fromTo(
        ref.current,
        {
          opacity: 0,
        },
        {
          // "power.out" means we fade in fast, then slow down
          ease: "power2.out",
          opacity: 1,
        },
      );
    }

    // And animate out again to invisible at 100% scroll, except last item.
    if (!isLast) {
      timeline.to(
        ref.current,
        {
          // "power.in" means we fade out slow, then speed up
          ease: "power2.in",
          opacity: 0,
        },
        0.5, // start at 50%
      );
    }

    return () => {
      timeline.kill();
    };
  }, [isFirst, isLast, wrappedOnActivate]);

  return (
    <div
      ref={ref}
      className={cn(
        "relative flex min-h-screen-safe flex-col place-content-center place-items-center gap-lg text-center",
        "overflow-hidden px-sm py-3xl-4xl text-surface/100 md:px-3xl-4xl",
        // Overlap the first slide on top of the sticky background stack.
        isFirst && "-mt-[100vh]",
      )}
    >
      {/* Slides can have an optional seed pattern at the bottom */}
      {item.pattern === true && (
        <SeedWave
          className="absolute bottom-[0] h-screen-safe w-screen bg-decor/100"
          seedPatternColor={item.patternColor}
        />
      )}

      <RichText
        data={item.text}
        {...context}
        classNames={{
          h1: cn(
            "t-strong-4xl-5xl -mt-md z-10 max-w-[min(21ch,100%)] text-balance max-md:text-strong-5xl",
          ),
          h2: "t-strong-3xl md:t-strong-4xl -mt-md z-10 max-w-[min(30ch,100%)] text-balance leading-snug",
          h3: "t-strong-2xl md:t-strong-3xl -mt-md z-10 max-w-[min(30ch,100%)] text-balance leading-snug",
          paragraph:
            "t-strong-lg-xl -mt-md z-10 max-w-[min(45ch,100%)] text-pretty leading-snug",
        }}
      />

      {item.ctaLink && item.ctaLabel && (
        <Button className="z-10" {...getLinkProps(item.ctaLink, context)}>
          {item.ctaLabel}
        </Button>
      )}
    </div>
  );
}

type SlidesBackgroundProps = {
  items: SlidesItemBlok[];
  activeItem: SlidesItemBlok;
};

/**
 * Stack of backgrounds, on top of each other, crossfading when activated.
 */
// TODO: upgrade next to 11.1 so we get responsive background images, and switch between backgrounds instead. (needs browser support first)
function SlideBackgrounds({ items, activeItem }: SlidesBackgroundProps) {
  // We are only interested in different backgrounds, no need to duplicate elements.
  const uniqBackgrounds = useMemo(
    () => uniqBy(items, item => item.background.filename),
    [items],
  );

  return (
    // The wrapper element is sticky, to keep it behind the content when scrolling.
    <div
      className={cn(
        "sticky top-[0] left-[0] z-0 h-screen-safe w-full overflow-hidden",
      )}
    >
      {uniqBackgrounds.map(item => (
        <div
          key={item._uid}
          // Each background image sits on top of each other.
          className={cn(
            "absolute top-[0] left-[0] grid h-full w-full place-items-center",
            "opacity-0 transition-opacity duration-extra-long [&[data-state='active']]:opacity-100",
          )}
          data-state={
            activeItem.background.filename === item.background.filename
              ? "active"
              : undefined
          }
          {...storyblokEditable(item)}
        >
          {item.background?.filename && (
            <>
              {/* Mobile */}
              <Image
                src={item.background.filename}
                width={500}
                height={1000}
                alt={item.background.alt}
                loader={buildImageLoader({
                  aspectRatio: 1 / 2,
                  focus: item.background.focus,
                })}
                className={cn(
                  "absolute h-[120%] w-full object-cover object-top md:hidden",
                )}
              />

              {/* Desktop */}
              <Image
                src={item.background.filename}
                width={2000}
                height={1000}
                alt={item.background.alt}
                loader={buildImageLoader({ aspectRatio: 2 / 1 })}
                className={cn(
                  "absolute h-[120%] w-full object-cover object-top max-md:hidden",
                )}
              />

              {/* Scrim on top of image, to make the text easier to read */}
              <div
                className="absolute h-full w-full bg-others-black transition-opacity duration-extra-long"
                style={{
                  opacity: activeItem.scrimOpacity
                    ? Number.parseFloat(activeItem.scrimOpacity) / 100
                    : 0.2,
                }}
              />
            </>
          )}
        </div>
      ))}
    </div>
  );
}

const fillEmptyBackgrounds = (items: SlidesItemBlok[]) => {
  if (!items[0].background.filename) return items;

  const resultItems: SlidesItemBlok[] = [];

  for (let i = 0; i < items.length; ++i) {
    if (items[i].background.filename) {
      resultItems.push(items[i]);
    } else {
      // copy previous background
      resultItems.push({
        ...items[i],
        background: resultItems[resultItems.length - 1].background,
      });
    }
  }

  return resultItems;
};
